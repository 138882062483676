import { useCallback, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import { IconDefinition as IconDefinitionSvg } from "@fortawesome/fontawesome-svg-core"
import cx from "classnames"

import ProjectCard from "../../components/ProjectCard"
import { useAccountUrlPrefix } from "../../hooks"
import { ProjectDataTypes } from "@/types/program"

interface ArrowTypes {
  onClick: () => void
  icon: IconDefinitionSvg
  className: string
}

interface ProgramsCarouselTypes {
  programsData: ProjectDataTypes[]
}

const Arrow = ({ onClick, icon, className }: ArrowTypes) => (
  <button
    type="button"
    onClick={onClick}
    className={cx(
      "similar-programs-slider-arrow bg-white absolute top-1/2 -translate-y-1/2",
      className
    )}
  >
    <FontAwesomeIcon icon={icon} />
  </button>
)

const ProgramsCarousel = ({ programsData = [] }: ProgramsCarouselTypes) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const accountUrlPrefix = useAccountUrlPrefix()

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === programsData.length - 1 ? 0 : prevIndex + 1
    )
  }, [programsData.length])

  const prevSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? programsData.length - 1 : prevIndex - 1
    )
  }, [programsData.length])

  if (programsData.length === 0) {
    return null
  }

  return (
    <div className="card card-content px-6 pt-6 pb-4 mb-6">
      <h4 className="text-overline mb-3">Partner Sponsored Programs</h4>

      <div className="relative">
        <div className="relative w-full max-w-4xl mx-auto overflow-hidden">
          <div
            className="flex transition-transform duration-500 ease-in-out"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {programsData.map((program: ProjectDataTypes) => (
              <div className="w-full flex-shrink-0" key={program.id}>
                <ProjectCard
                  className={cx(
                    "w-full",
                    `sponsored-program sponsored-${program.id}`
                  )}
                  description={program.description_short}
                  imageUrl={program.image_url}
                  enrollmentDeadlineDate={program.enrollment_deadline_date}
                  enrollmentDeadlineType={program.enrollment_deadline_type}
                  badge_display={program.badge_display}
                  name={program.name}
                  type={program.type}
                  term={program.term}
                  potentialEarnings={program.potential_earnings}
                  to={`${accountUrlPrefix}/programs/${program.id}`}
                  showMinimalInfo={true}
                  isVerified={program.is_verified}
                />
              </div>
            ))}
          </div>
        </div>

        {currentIndex > 0 ? (
          <Arrow
            onClick={prevSlide}
            icon={faArrowLeft}
            className="-left-2 sponsored-left"
          />
        ) : null}

        {currentIndex < programsData?.length - 1 ? (
          <Arrow
            onClick={nextSlide}
            icon={faArrowRight}
            className="-right-2 sponsored-right"
          />
        ) : null}
      </div>
    </div>
  )
}

export default ProgramsCarousel
