import { useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { FormikProps } from "formik"
import { AxiosError } from "axios"
import { DialogDisclosure, DialogStateReturn } from "reakit/Dialog"
import cx from "classnames"

import { DEADLINE_TYPE, LANDOWNER_STATUS } from "../../shared/constants"
import { SubmitButton } from "../../components/SubmitButton"
import { ActionPermissionWrapper } from "../../components/ActionPermissionWrapper"
import { Toast } from "../../components/Toast"
import { genericErrMsg } from "../../api/auth"
import { useUpdateAccountProject } from "../../hooks"
import { LandownerStatusType } from "@/types/program"
import { AccountRoleType } from "@/types/account"

interface RequestInformationBtnTypes {
  requestInfoDialog?: DialogStateReturn
  landowner_status: LandownerStatusType
  projectId: string
  accountId: string
  onRequestCallSubmit?: ((success?: boolean) => void) | null
  sourceIdentifier: string
  accountRole: AccountRoleType
  enrollmentDeadlineType: string
  ctaOverride: string
  ctaOverrideUrl: string
  requires_service_provider?: boolean
  has_service_provider_coverage?: boolean
  formikProps?: FormikProps<unknown>
  customBtnText?: string
}

const RequestInformationBtn = ({
  requestInfoDialog,
  landowner_status,
  projectId,
  accountId,
  onRequestCallSubmit,
  sourceIdentifier,
  accountRole,
  enrollmentDeadlineType,
  ctaOverride,
  ctaOverrideUrl,
  requires_service_provider,
  has_service_provider_coverage,
  formikProps,
  customBtnText,
}: RequestInformationBtnTypes) => {
  const queryClient = useQueryClient()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { mutateAsync: updateAccountProject } = useUpdateAccountProject(
    queryClient,
    accountId,
    projectId
  )

  const hasRequestedCall: boolean =
    landowner_status !== null &&
    landowner_status !== undefined &&
    landowner_status !== LANDOWNER_STATUS.not_interested &&
    landowner_status !== LANDOWNER_STATUS.lo_not_interested &&
    landowner_status !== LANDOWNER_STATUS.internal_dq

  const isCustomCta: boolean =
    ctaOverride?.length > 0 && ctaOverrideUrl?.length > 0

  const disableRequestCall: boolean = formikProps
    ? !(formikProps.isValid && formikProps.dirty)
    : isCustomCta
      ? false
      : hasRequestedCall

  let REQUEST_CALL

  if (hasRequestedCall) {
    REQUEST_CALL = "Requested"
  } else if (ctaOverride?.length > 0) {
    REQUEST_CALL = ctaOverride
  } else if (enrollmentDeadlineType === DEADLINE_TYPE.pending_interest) {
    REQUEST_CALL = "Join Waitlist"
  } else if (requires_service_provider && !has_service_provider_coverage) {
    REQUEST_CALL = "Join Waitlist"
  } else {
    REQUEST_CALL = customBtnText ? customBtnText : "Request Call"
  }

  const onSubmitAction = async () => {
    setIsSubmitting(true)
    // DEV: this to detect if the button is part of a formik form (i.e. PreferredContactMethodForm)
    // which has its own submit actions
    if (formikProps && formikProps.handleSubmit) {
      formikProps.handleSubmit()
      setIsSubmitting(false)
    } else {
      await updateAccountProject(
        { status: LANDOWNER_STATUS.request_information },
        {
          onSuccess: () => {
            setIsSubmitting(false)
            onRequestCallSubmit && onRequestCallSubmit(true)
          },
          onError: (error) => {
            const err = error as AxiosError
            setIsSubmitting(false)
            Toast.error(err?.message || genericErrMsg)
            onRequestCallSubmit && onRequestCallSubmit(false)
          },
        }
      )
    }
  }

  // buttons to either open an external link or move to next step in EligibilityInterestModal
  if (!requestInfoDialog || isCustomCta) {
    const sourceIdClass = isCustomCta
      ? `list-land-btn-${sourceIdentifier}`
      : `request-call-btn-${sourceIdentifier}`

    return (
      <ActionPermissionWrapper accountRole={accountRole} action="editAccount">
        <SubmitButton
          onClick={() => {
            onSubmitAction()
          }}
          isSubmitting={isSubmitting}
          disabled={disableRequestCall}
          typeButton
          className={cx(
            `text-base font-bold btn2 btn2-primary w-full sm:w-auto ${sourceIdClass}`,
            {
              enabled: !disableRequestCall,
            }
          )}
        >
          {isCustomCta ? ctaOverride : REQUEST_CALL}
        </SubmitButton>
      </ActionPermissionWrapper>
    )
  }

  // opens a dialog to confirm requesting a call. Used in the project details page
  return (
    <ActionPermissionWrapper accountRole={accountRole} action="editAccount">
      <DialogDisclosure
        {...requestInfoDialog}
        className={cx(
          "text-base font-bold btn2 btn2-primary w-full sm:w-auto",
          `request-call-btn-${sourceIdentifier}`,
          {
            enabled: !disableRequestCall,
          }
        )}
        disabled={disableRequestCall}
      >
        {REQUEST_CALL}
      </DialogDisclosure>
    </ActionPermissionWrapper>
  )
}

export default RequestInformationBtn
