import { ReactNode, useEffect, useState } from "react"
import mapboxglSupported from "@mapbox/mapbox-gl-supported"

import { MapboxGLNotSupported } from "../../components/MapVisualization"
import ProjectMapVisualization from "../ProjectDetails/ProjectMap/ProjectMapVisualization"
import ProjectMapParcels from "../ProjectDetails/ProjectMap/ProjectMapParcels"
import ProjectMapSkeleton from "../ProjectDetails/ProjectMap/ProjectMapSkeleton"
import { getViewportFromFeature } from "../ProjectDetails/ProjectMap/helpers"
import { genericErrMsg } from "../../api/auth"
import { useGetParcelGeoms } from "../../hooks/index"
import useViewport from "../../hooks/useViewport"
import { handleNextFeature, handlePrevFeature } from "./helpers"
import { FeatureCollection, Geometry, Properties } from "@turf/helpers"
import { ViewportTypes } from "@/types"

interface PropertyMapTypes {
  accountId: string
  footer?: ReactNode
}

// This is similar to ProjectMap except removing project-related eligibility elements
const PropertyMap = ({ accountId, footer }: PropertyMapTypes) => {
  const [activeFeature, setActiveFeature] = useState<number | null>(0)
  const [viewport, setViewport] = useState<ViewportTypes | null>(null)
  const { width } = useViewport()

  const isMobile = width < 768

  // DEV: we only care about parcel data because this isn't about eligibility, just a parcel viewer
  const { data: parcelData, isLoading: parcelIsLoading } = useGetParcelGeoms<
    FeatureCollection<Geometry, Properties>,
    Error
  >(accountId)

  const multipleParcels =
    Array.isArray(parcelData?.features) && parcelData?.features.length > 1

  useEffect(() => {
    if (parcelData !== undefined) {
      setViewport(getViewportFromFeature(parcelData?.features[0]))
    }
  }, [parcelData])

  if (!mapboxglSupported.supported()) {
    const reason = mapboxglSupported.notSupportedReason()

    return (
      <div className="container xl:max-w-1120 mt-[66px]">
        <div className="lg:max-w-[844px] h-[400px] lg:flex relative rounded bg-gray2 overflow-hidden p-6">
          <MapboxGLNotSupported reason={reason} />
        </div>
      </div>
    )
  }

  return (
    <div className="card card-content relative my-4 p-0">
      <div className="lg:flex relative rounded">
        {parcelIsLoading ? (
          <ProjectMapSkeleton animate />
        ) : parcelData ? (
          <>
            {isMobile ? footer : null}
            <ProjectMapVisualization
              viewport={viewport}
              setViewport={setViewport}
              parcelData={parcelData}
              activeFeature={activeFeature}
              isIneligible={false}
              enableEligibility={false}
            />
            {multipleParcels && (
              <ProjectMapParcels
                isIneligible={false}
                parcelData={parcelData}
                activeFeature={activeFeature}
                setActiveFeature={setActiveFeature}
                setViewport={setViewport}
                onNextFeatureClick={handleNextFeature}
                onPrevFeatureClick={handlePrevFeature}
                enableEligibility={false}
                className="property-map-landcover-report"
              />
            )}
          </>
        ) : (
          <ProjectMapSkeleton
            message={
              <div className="lg:max-w-[844px] h-[400px] lg:flex relative rounded bg-gray2 overflow-hidden p-6">
                <div className="bg-gray2 w-full h-full">
                  <div className="w-1/2 mx-auto mt-1/4 bg-white p-12">
                    {genericErrMsg}
                  </div>
                </div>
              </div>
            }
          />
        )}
      </div>
      {!isMobile ? footer : null}
    </div>
  )
}

export default PropertyMap
