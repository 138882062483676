import { ReactNode } from "react"
import assert from "assert"
import cx from "classnames"
import {
  ACTION_NEEDED__ACCESS_INFO_INCOMPLETE,
  CYCLE_INFOS_BY_KEY,
  DATETIME_MONTH_DAY,
} from "../shared/constants"
import {
  getAccessInfoPeriodForCycle,
  getReviewRequestPeriodEndDateStr,
} from "../shared/utils"
import { AccountTypes } from "../types/account"
import {
  AccessInfoSubmissionDeadlineTypes,
  AccountCycleKeyType,
  AccountCycleStatusType,
  AccountCycleTypes,
  CycleInfoTypes,
} from "../types/accountCycles"

const COLOR_ACTION_NEEDED_REQUEST_ASSESSMENT = "yellow"
const COLOR_ACTION_NEEDED_SUBMIT_OFFER = "orange"
const COLOR_ACTION_NEEDED_CONFIRM_PAYMENT_AMOUNT = "yellow"
const COLOR_ACTION_NEEDED_PAYMENT_INFO_NEEDED = "yellow"
const COLOR_ACTION_NEEDED_PAYMENT_DELIVERY_FAILED = "yellow"

interface BadgeTypes {
  color: "red" | "yellow" | "orange" | "green" | "gray" | "white"
  children: ReactNode
}

interface CycleBadgeTypes {
  account?: AccountTypes
  accountCycle: AccountCycleTypes
  forceStatus?: AccountCycleStatusType
}

interface CreatedTypes {
  cycleInfo: CycleInfoTypes
}
interface AssessedTypes {
  cycleInfo: CycleInfoTypes
}
interface AgreementSentTypes {
  cycleInfo: CycleInfoTypes
}
interface AccessInfoIncompleteTypes {
  cycleKey: AccountCycleKeyType
  cycleInfo: CycleInfoTypes
}
interface PaymentAmountConfirmedTypes {
  account: AccountTypes
}
interface ResultsDeliveredTypes {
  cycleInfo: CycleInfoTypes
}

export const Badge = ({ color, children }: BadgeTypes) => {
  const badgeColor = `badge-${color}`

  return <span className={cx("badge", badgeColor)}>{children}</span>
}

export const CycleBadge = ({
  account,
  accountCycle,
  forceStatus,
}: CycleBadgeTypes) => {
  account = account || accountCycle?.account
  assert(account, "No `account` nor `accountCycle.account` provided")

  let cycleKey: AccountCycleKeyType | undefined
  let status: AccountCycleStatusType | undefined

  if (accountCycle && accountCycle.cycle_key) {
    cycleKey = accountCycle.cycle_key
    status = accountCycle.status
  } else {
    return null
  }

  if (forceStatus) {
    status = forceStatus
  }

  const cycleInfo = CYCLE_INFOS_BY_KEY[cycleKey] as unknown as CycleInfoTypes

  let BadgeComponent
  let badgeProps = {}

  if (status === "created") {
    BadgeComponent = CycleBadge.Created
    badgeProps = { cycleInfo }
  } else if (status === "payment_info_needed") {
    BadgeComponent = CycleBadge.PaymentInfoNeeded
  } else if (status === "payment_pending_approval") {
    BadgeComponent = CycleBadge.PaymentPendingApproval
  } else if (status === "payment_verified") {
    BadgeComponent = CycleBadge.PaymentVerified
  } else if (status === "assessment_requested") {
    BadgeComponent = CycleBadge.AssessmentRequested
  } else if (status.match(/^ineligible/)) {
    BadgeComponent = CycleBadge.AssessedIneligible
  } else if (status === "assessed") {
    BadgeComponent = CycleBadge.Assessed
    badgeProps = { cycleInfo }
  } else if (status === "ncapx_sale_agreement_sent") {
    BadgeComponent = CycleBadge.AgreementSent
    badgeProps = { cycleInfo }
  } else if (status === "ncapx_sale_agreement_signed") {
    BadgeComponent = CycleBadge.AgreementSigned
  } else if (status === ACTION_NEEDED__ACCESS_INFO_INCOMPLETE) {
    BadgeComponent = CycleBadge.AccessInfoIncomplete
    badgeProps = { cycleKey, cycleInfo }
  } else if (status === "bid_accepted") {
    BadgeComponent = CycleBadge.Accepted
  } else if (status === "results_delivered_ineligible") {
    BadgeComponent = CycleBadge.ResultsDeliveredIneligible
  } else if (status === "results_delivered") {
    BadgeComponent = CycleBadge.ResultsDelivered
    badgeProps = { cycleInfo }
  } else if (status === "results_review_in_progress") {
    BadgeComponent = CycleBadge.ResultsReviewInProgress
  } else if (status === "payment_amount_confirmed") {
    BadgeComponent = CycleBadge.PaymentAmountConfirmed
    badgeProps = { account }
  } else if (status === "payment_sent") {
    BadgeComponent = CycleBadge.PaymentSent
  } else if (status === "payment_delivery_failed") {
    BadgeComponent = CycleBadge.PaymentDeliveryFailed
  } else if (status === "payment_delivered") {
    BadgeComponent = CycleBadge.PaymentDelivered
  } else {
    throw new Error(`Unrecognized status ${status}`)
  }

  return <BadgeComponent {...(badgeProps as any)} />
}

CycleBadge.Created = ({ cycleInfo }: CreatedTypes) => (
  <Badge color={COLOR_ACTION_NEEDED_REQUEST_ASSESSMENT}>
    Request Assessment
    {cycleInfo.showDates && (
      <> by {cycleInfo.eligibilityReportRequestPeriodEnd}</>
    )}
  </Badge>
)

CycleBadge.AssessmentRequested = () => (
  <Badge color="gray">Determining Eligibility</Badge>
)

CycleBadge.AssessedIneligible = () => <Badge color="gray">Ineligible</Badge>

CycleBadge.Assessed = ({ cycleInfo }: AssessedTypes) => (
  <Badge color={COLOR_ACTION_NEEDED_SUBMIT_OFFER}>
    Submit Offer by {cycleInfo.submissionDeadline}
  </Badge>
)

CycleBadge.AgreementSent = ({ cycleInfo }: AgreementSentTypes) => (
  <Badge color={COLOR_ACTION_NEEDED_SUBMIT_OFFER}>
    DocuSign Sent, Due by {cycleInfo.submissionDeadline}
  </Badge>
)

CycleBadge.AgreementSigned = () => (
  <Badge color="gray">Searching for Buyer Match</Badge>
)

CycleBadge.AccessInfoIncomplete = ({
  cycleKey,
  cycleInfo,
}: AccessInfoIncompleteTypes) => {
  const accessInfoPeriod = getAccessInfoPeriodForCycle(
    cycleKey
  ) as keyof AccessInfoSubmissionDeadlineTypes

  return (
    <Badge color="white">
      Add Access Info by{" "}
      {cycleInfo.accessInfoSubmissionDeadlineByPeriod[accessInfoPeriod]}
    </Badge>
  )
}

CycleBadge.Accepted = () => <Badge color="green">Offer Accepted</Badge>

CycleBadge.ResultsDeliveredIneligible = () => (
  <Badge color="red">Ineligible for Payment</Badge>
)

CycleBadge.ResultsDelivered = ({ cycleInfo }: ResultsDeliveredTypes) => {
  const reviewPeriodEndDateStr = getReviewRequestPeriodEndDateStr(cycleInfo, {
    format: DATETIME_MONTH_DAY,
  })

  return (
    <Badge color={COLOR_ACTION_NEEDED_CONFIRM_PAYMENT_AMOUNT}>
      Confirm Payment Amount by {reviewPeriodEndDateStr}
    </Badge>
  )
}

CycleBadge.ResultsReviewInProgress = () => (
  <Badge color="gray">Results Review In Progress</Badge>
)

CycleBadge.PaymentAmountConfirmed = ({
  account,
}: PaymentAmountConfirmedTypes) =>
  account.payment_info_complete ? (
    <Badge color="green">Payment Amount Confirmed</Badge>
  ) : (
    <Badge color={COLOR_ACTION_NEEDED_PAYMENT_INFO_NEEDED}>
      Payment Info Needed
    </Badge>
  )

CycleBadge.PaymentSent = () => <Badge color="green">Payment Sent</Badge>

CycleBadge.PaymentDeliveryFailed = () => (
  <Badge color={COLOR_ACTION_NEEDED_PAYMENT_DELIVERY_FAILED}>
    Review payment info
  </Badge>
)

CycleBadge.PaymentDelivered = () => (
  <Badge color="green">Payment Delivered</Badge>
)

CycleBadge.PaymentInfoNeeded = () => (
  <Badge color="gray">Payment Info Needed</Badge>
)

CycleBadge.PaymentPendingApproval = () => (
  <Badge color="gray">Pending Approval</Badge>
)

CycleBadge.PaymentVerified = CycleBadge.Verified = () => (
  <Badge color="green">Verified</Badge>
)
