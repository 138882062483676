import { Link } from "react-router-dom"

import { AcreageToolTip } from "../../components/AcreageToolTip"
import { shortenAcreage } from "../../utils"
import {
  buildAccountAbsoluteUrl,
  useAccountId,
  useAccountUrlPrefix,
} from "../../hooks"

interface DashboardPropertyTypes {
  acreage: number
}

const DashboardProperty = ({ acreage }: DashboardPropertyTypes) => {
  const accountId = useAccountId()
  const accountUrlPrefix = useAccountUrlPrefix()
  const accountUrl = buildAccountAbsoluteUrl(accountId)

  return (
    <div className="card card-content px-6 pt-6 pb-4 mb-6">
      <h4 className="text-overline mb-3">Property</h4>
      <div className="mb-6">
        <div className="big-stat mr-16">
          <span className="big-stat-number">{shortenAcreage(acreage)}</span>

          <span className="big-stat-unit">
            total acres <AcreageToolTip />
          </span>
        </div>
      </div>

      <div className="border-cloud mb-4">
        <Link
          to={{
            pathname: `${accountUrlPrefix}/settings/property-boundaries`,
          }}
          state={{ from: { pathname: accountUrl } }}
          className="view-property-boundaries link"
        >
          View Property Boundaries
        </Link>
      </div>

      <hr className="border-cloud mb-4" />

      <div>
        <Link
          to={`${accountUrlPrefix}/settings`}
          className="edit-account-settings link"
        >
          Edit Account Settings
        </Link>
      </div>
    </div>
  )
}

export default DashboardProperty
