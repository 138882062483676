import { Dispatch, SetStateAction } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import cx from "classnames"

import OnboardingBtnContainer from "./OnboardingBtnContainer"
import OnboardingSurveyHeader from "./OnboardingSurveyHeader"
import { formSteps } from "./OnboardingFormSteps"
import { SubmitButton } from "../../components/SubmitButton"
import { Toast } from "../../components/Toast"
import { useAccountId, useUpdateAccountInterest } from "../../hooks"
import ONBOARDING from "../../images/onboarding-survey.webp"

interface OnboardingFormTypes {
  onboardingStep: number
  setOnboardingStep: Dispatch<SetStateAction<number>>
  setSurveyCompleted: Dispatch<SetStateAction<boolean>>
  isMobile: boolean
}

interface OnboardingFormValuesTypes {
  onboarding_goal: string
  land_goal: string
  start_program_intention: string
  project_interest: string[]
}

const validationSchema = Yup.object().shape({
  onboarding_goal: Yup.string().required("Please choose an option"),
  land_goal: Yup.string().required("Please choose an option"),
  start_program_intention: Yup.string().required("Please choose an option"),
  project_interest: Yup.array().min(1, "Choose at least one interest"),
})

const OnboardingForm = ({
  onboardingStep,
  setOnboardingStep,
  setSurveyCompleted,
  isMobile,
}: OnboardingFormTypes) => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const { mutateAsync: updateAccountInterest } = useUpdateAccountInterest(
    queryClient,
    accountId,
    {
      onSuccess: () => {
        setSurveyCompleted(true)
      },
      onError: (error: AxiosError) => {
        Toast.error(
          error?.message || "An error occurred while adding your information."
        )
      },
    }
  )

  const handleSubmit = async (values: OnboardingFormValuesTypes) => {
    const postData = {
      onboarding_goal: values.onboarding_goal,
      land_goal: values.land_goal,
      start_program_intention: values.start_program_intention,
      project_interest: values.project_interest.reduce((acc, interest) => {
        return { ...acc, [interest]: true }
      }, {}),
    }
    await updateAccountInterest(postData)
  }

  const questionKeys = Object.keys(formSteps)

  return (
    <Formik
      initialValues={{
        onboarding_goal: "",
        land_goal: "",
        start_program_intention: "",
        project_interest: [],
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        const fieldValue =
          formikProps.values[
            formSteps[onboardingStep as keyof typeof formSteps]
              .id as keyof OnboardingFormValuesTypes
          ]

        const disabled =
          (typeof fieldValue === "string" && fieldValue === "") ||
          (typeof fieldValue === "boolean" && fieldValue === false) ||
          fieldValue === null ||
          fieldValue === undefined

        return (
          <Form>
            {questionKeys.map((questionIdx, index) => {
              return (
                <div
                  key={
                    formSteps[Number(questionIdx) as keyof typeof formSteps].id
                  }
                  className={cx({ hidden: index !== onboardingStep })}
                >
                  <OnboardingSurveyHeader
                    text={
                      formSteps[Number(questionIdx) as keyof typeof formSteps]
                        .headerText
                    }
                    subheader={
                      formSteps[Number(questionIdx) as keyof typeof formSteps]
                        .subheader
                    }
                    isMobile={isMobile}
                    headerImg={
                      <img
                        src={ONBOARDING}
                        alt="Ebony Tree Image"
                        className="w-[184px] h-auto block"
                      />
                    }
                  />

                  {formSteps[
                    Number(questionIdx) as keyof typeof formSteps
                  ].formInput(
                    formSteps[Number(questionIdx) as keyof typeof formSteps]
                      .options
                  )}
                </div>
              )
            })}

            <OnboardingBtnContainer>
              {onboardingStep === questionKeys.length - 1 ? (
                <SubmitButton
                  className="btn2 btn2-primary font-semibold md:self-start md:mt-10"
                  isSubmitting={formikProps.isSubmitting}
                  disabled={disabled}
                >
                  Next
                </SubmitButton>
              ) : (
                <button
                  type="button"
                  className="btn2 btn2-primary font-semibold md:self-start md:mt-10"
                  onClick={() => setOnboardingStep((prevStep) => prevStep + 1)}
                  disabled={disabled}
                >
                  Next
                </button>
              )}
            </OnboardingBtnContainer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default OnboardingForm
