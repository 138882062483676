import { ReactNode, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { Dialog, DialogStateReturn, useDialogState } from "reakit/Dialog"
import { Formik, FormikValues } from "formik"
import * as yup from "yup"

import { Modal } from "../../components/Modal"
import { ButtonPair } from "../../components/ButtonPair"
import { Toast } from "../../components/Toast"
import { SubmitButton } from "../../components/SubmitButton"
import { CloseButton } from "../../components/CloseButton"
import PreferredContactMethodForm, {
  PreferredContactMethodFormValuesTypes,
} from "./PreferredContactMethodForm"
import { useUpdateAccountProject, useUpdateProfile } from "../../hooks"
import {
  DEADLINE_TYPE,
  phoneRegex,
  PREFERRED_CONTACT_METHOD,
  PHONE_NUMBER,
} from "../../shared/constants"
import { STEPS } from "./EligibilityInterestModalSteps"
import { genericErrMsg } from "../../api/auth"
import { AccountRoleType } from "@/types/account"
import { DeadlineType, Profile } from "@/types"
import { LandownerStatusType } from "@/types/program"

interface RequestInformationModalTypes {
  dialog: DialogStateReturn
  accountId: string
  projectId: string
  onClose?: () => void
  accountRole: AccountRoleType
  enrollmentDeadlineType: DeadlineType
  img: string
  profile: Profile
  requires_service_provider: boolean
  has_service_provider_coverage: boolean
  ctaOverride: string
  ctaOverrideUrl: string
  landowner_status: LandownerStatusType
}

const validationSchema = (isValidPhoneNumber: boolean) => {
  return yup.object().shape({
    [PREFERRED_CONTACT_METHOD]: yup
      .array()
      .min(1, "Please provide a preferred contact method")
      .of(yup.string().required())
      .required("Please provide a preferred contact method"),
    [PHONE_NUMBER]: yup
      .string()
      .when(PREFERRED_CONTACT_METHOD, (methodsArray, schema) => {
        // methodsArray is an array of 1 array strings
        const methods = [...methodsArray[0]]
        return !isValidPhoneNumber &&
          (methods.includes("phone") || methods.includes("text"))
          ? yup
              .string()
              .required("Please enter a phone number.")
              .matches(phoneRegex, "Please provide a valid phone number")
          : schema
      }),
  })
}

const RequestInformationModal = ({
  dialog,
  accountId,
  projectId,
  onClose,
  accountRole,
  enrollmentDeadlineType,
  img,
  profile,
  requires_service_provider,
  has_service_provider_coverage,
  ctaOverride,
  ctaOverrideUrl,
  landowner_status,
}: RequestInformationModalTypes) => {
  const queryClient = useQueryClient()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const notInterestedDialog = useDialogState({ animated: true })
  const outOfCoverage =
    requires_service_provider && !has_service_provider_coverage

  const { mutateAsync: updateAccountProject } = useUpdateAccountProject(
    queryClient,
    accountId,
    projectId,
    {
      onSuccess: () => {
        setIsSubmitting(false)
        dialog.hide()
        Toast.success(
          enrollmentDeadlineType === DEADLINE_TYPE.pending_interest
            ? "Congratulations! You're on the waitlist."
            : "We've received your request for more information. We'll be in touch soon!"
        )
        onClose && onClose()
      },
      onError: (error) => {
        const err = error as AxiosError
        setIsSubmitting(false)
        dialog.hide()
        Toast.error(
          err?.message || "An error occurred while updating your preferences."
        )
      },
    }
  )

  const newAccountProjectValues = {
    status: "request_information",
  }

  const { mutateAsync: updateProfile } = useUpdateProfile(queryClient)

  const updateAccountProjectOptions = {
    onSuccess: () => {
      if (ctaOverride?.length > 0 && ctaOverrideUrl?.length > 0) {
        window.open(ctaOverrideUrl, "_blank", "noopener")
      }
    },
    onError: (error: unknown) => {
      const err = error as AxiosError
      Toast.error(err?.message || genericErrMsg)
    },
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    await updateAccountProject(
      newAccountProjectValues,
      updateAccountProjectOptions
    )
  }

  const handleFormSubmit = async (values: FormikValues) => {
    const newValues = {
      preferred_contact_method: values.preferred_contact_method,
      ...(values.preferred_contact_method.includes("phone") ||
      values.preferred_contact_method.includes("text")
        ? { phone_number: values.phone_number }
        : {}),
    }

    await updateProfile(newValues, {
      onSuccess: () => {
        const handleUpdateAccountProject = async () => {
          if (
            enrollmentDeadlineType === DEADLINE_TYPE.pending_interest ||
            outOfCoverage
          ) {
            await updateAccountProject(newAccountProjectValues)
          } else {
            setIsSubmitting(true)
            await updateAccountProject(
              newAccountProjectValues,
              updateAccountProjectOptions
            )
          }
        }

        handleUpdateAccountProject()
      },
      onError: (error) => {
        const err = error as AxiosError
        Toast.error(err?.message || genericErrMsg)
      },
    })
  }

  const isValidPhoneNumber = phoneRegex.test(profile?.phone_number || "")

  const modalBodyText = requires_service_provider
    ? "When you request a call, NCX will share your interest and contact information with a forester that services your area. The forester will follow up to learn more about your goals and the services that interest you."
    : "When you request a call, NCX will share your interest and contact information with the program partner. The program partner will follow up with you to discuss their program in more detail."

  if (
    enrollmentDeadlineType === DEADLINE_TYPE.pending_interest ||
    outOfCoverage
  ) {
    let headline: ReactNode
    let body: ReactNode
    if (enrollmentDeadlineType === DEADLINE_TYPE.pending_interest) {
      headline = STEPS.ELIGIBLE.renderHeadline({ enrollmentDeadlineType })
      body = STEPS.ELIGIBLE.renderBody({ enrollmentDeadlineType, img })
    } else if (outOfCoverage) {
      headline = STEPS.OUTOFCOVERAGE.renderHeadline({})
      body = STEPS.OUTOFCOVERAGE.renderBody({ img }) // {img}
    }

    return (
      <Dialog
        {...dialog}
        aria-label="Join Waitlist"
        className="dialog-full"
        hideOnEsc={true}
        hideOnClickOutside={false}
      >
        <div>
          <div className="container xl:max-w-1120 pt-6 pb-16 lg:pt-16">
            <div className="flex flex-col-reverse md:flex-row justify-between gap-4 mb-7 lg:mb-10">
              {headline}
              <CloseButton
                className="shrink-0 justify-self-end self-end md:self-auto rounded focus:outline-none text-charcoal-500 font-bold xl:absolute xl:top-64 xl:right-64"
                aria-label="Close modal"
                onClick={dialog.hide}
              />
            </div>

            {body}

            {profile.preferred_contact_method.length === 0 ? (
              <Formik<PreferredContactMethodFormValuesTypes>
                initialValues={{
                  preferred_contact_method: [],
                  phone_number: profile?.phone_number,
                }}
                validationSchema={() => validationSchema(isValidPhoneNumber)}
                onSubmit={handleFormSubmit}
              >
                {(formikProps) => (
                  <PreferredContactMethodForm
                    formikProps={formikProps}
                    dialog={dialog}
                    accountRole={accountRole}
                    enrollmentDeadlineType={enrollmentDeadlineType}
                    notInterestedDialog={notInterestedDialog}
                    requires_service_provider={requires_service_provider}
                    ctaOverride={ctaOverride}
                    ctaOverrideUrl={ctaOverrideUrl}
                    isValidPhoneNumber={isValidPhoneNumber}
                    landowner_status={landowner_status}
                    projectId={projectId}
                    accountId={accountId}
                  />
                )}
              </Formik>
            ) : (
              <ButtonPair
                className="mx-auto sm:mx-0 mt-6"
                primary={
                  <SubmitButton
                    onClick={() => {
                      handleSubmit()
                    }}
                    isSubmitting={isSubmitting}
                    typeButton
                  >
                    Join Waitlist
                  </SubmitButton>
                }
                secondary={
                  <button type="button" onClick={dialog.hide}>
                    Go Back
                  </button>
                }
              />
            )}
          </div>
        </div>
      </Dialog>
    )
  }

  return (
    <Modal
      header="Request Call?"
      aria-label="Request call"
      dialog={dialog}
      className="max-w-716 p-5"
    >
      {profile.preferred_contact_method.length === 0 ||
      (profile.preferred_contact_method.includes("phone") &&
        profile.phone_number === "") ? (
        <Formik<PreferredContactMethodFormValuesTypes>
          initialValues={{
            preferred_contact_method: profile?.preferred_contact_method || [],
            phone_number: profile?.phone_number,
          }}
          validationSchema={() => validationSchema(isValidPhoneNumber)}
          onSubmit={handleFormSubmit}
        >
          {(formikProps) => (
            <PreferredContactMethodForm
              formikProps={formikProps}
              dialog={dialog}
              accountRole={accountRole}
              enrollmentDeadlineType={enrollmentDeadlineType}
              notInterestedDialog={notInterestedDialog}
              requires_service_provider={requires_service_provider}
              ctaOverride={ctaOverride}
              ctaOverrideUrl={ctaOverrideUrl}
              isValidPhoneNumber={isValidPhoneNumber}
              landowner_status={landowner_status}
              projectId={projectId}
              accountId={accountId}
              modalBodyText={modalBodyText}
              submitBtnText="Send Request"
            />
          )}
        </Formik>
      ) : (
        <>
          <p className="mb-4 pr-9">{modalBodyText}</p>

          <ButtonPair
            primary={
              <SubmitButton
                onClick={() => {
                  handleSubmit()
                }}
                isSubmitting={isSubmitting}
                typeButton
              >
                Send Request
              </SubmitButton>
            }
            secondary={
              <button type="button" onClick={dialog.hide}>
                Go Back
              </button>
            }
          />
        </>
      )}
    </Modal>
  )
}

export default RequestInformationModal
