import React from "react"
import { Link } from "react-router-dom"

import {
  faList,
  faHourglassEnd,
  faClock,
} from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

import { ProjectBadge } from "./ProjectBadge"
import ProjectVerificationBadge from "./ProjectVerificationBadge"
import IconText from "./IconText"
import {
  getEnrollmentDeadline,
  getProjectType,
  getProjectBadgeType,
  getProjectBadgeText,
  getProjectEarningsNullValue,
} from "../shared/utils"
import { formatCurrency } from "../utils"
import {
  BadgeDisplayType,
  ProjectBadgeType,
  ProgramCategoryType,
} from "../types/constants"
import { faDollarSign } from "@fortawesome/pro-duotone-svg-icons"

interface ProjectCardTypes {
  className?: string
  badge_display: BadgeDisplayType
  description: string
  enrollmentDeadlineDate: string | undefined | null
  enrollmentDeadlineType: string | undefined | null
  imageUrl: string
  name: string
  type: ProgramCategoryType
  term: string | null | undefined
  potentialEarnings: number | null | undefined
  to: string
  isVerified?: boolean
  showMinimalInfo?: boolean
  onClick?: () => void
}

const ProjectCard = ({
  className,
  badge_display,
  description,
  enrollmentDeadlineDate,
  enrollmentDeadlineType,
  imageUrl,
  name,
  type,
  term,
  potentialEarnings,
  to,
  isVerified,
  showMinimalInfo = false,
  onClick,
}: ProjectCardTypes) => {
  const badgeType = getProjectBadgeType(badge_display)
  const badgeText = getProjectBadgeText(badge_display)
  const projectType = getProjectType(type, "short")
  const enrollmentDeadline = getEnrollmentDeadline(
    enrollmentDeadlineType,
    enrollmentDeadlineDate
  )

  return (
    <div className="relative flex">
      {!showMinimalInfo ? (
        <div className="verification-badge-card">
          <ProjectVerificationBadge
            isVerified={isVerified}
            includeLabel={false}
          />
        </div>
      ) : null}

      <Link
        to={to}
        className={cx("project-card min-w-full", className)}
        aria-label={name}
        onClick={onClick}
      >
        {!showMinimalInfo ? (
          <div className="mb-3">
            <ProjectBadge type={badgeType as ProjectBadgeType} size="sm">
              {badgeText}
            </ProjectBadge>
          </div>
        ) : null}

        <div className="mb-3">
          <img
            src={imageUrl}
            alt=""
            className={cx("w-full h-121 sm:h-222 object-cover rounded", {
              grayscale: !isVerified,
            })}
          />
        </div>

        <div className="line-clamp-2 mb-2">
          <h3 className="font-semibold text-lg leading-130 mb-0">{name}</h3>
        </div>

        {!showMinimalInfo ? (
          <div className="line-clamp-2 mb-3.5">
            <p className="text-base leading-130 mb-0">{description}</p>
          </div>
        ) : null}

        <div
          className={cx(
            "grid grid-cols-2-max-content gap-2 mt-auto mb-0",
            showMinimalInfo ? "grid-rows-1" : "grid-rows-2"
          )}
        >
          <IconText
            icon={faList}
            className="text-sm text-dusk-700 leading-6"
            iconClass="text-base mr-1"
          >
            {projectType}
          </IconText>
          <IconText
            icon={faHourglassEnd}
            className="text-sm text-dusk-700 leading-6"
            iconClass="text-base mr-1"
          >
            {enrollmentDeadline}
          </IconText>

          {!showMinimalInfo ? (
            <IconText
              icon={faClock}
              className="text-sm text-dusk-700 leading-6"
              iconClass="text-base mr-1"
            >
              {term === null ? "Term length varies" : `${term} year term`}
            </IconText>
          ) : null}
          {!showMinimalInfo ? (
            <IconText
              icon={faDollarSign}
              className="text-sm text-dusk-700 leading-6"
              iconClass="text-base mr-1"
            >
              {potentialEarnings === null || potentialEarnings === undefined
                ? getProjectEarningsNullValue(type)
                : `${formatCurrency(potentialEarnings)}/acre`}
            </IconText>
          ) : null}
        </div>
      </Link>
    </div>
  )
}

export default ProjectCard
