import { useState, useEffect, useRef } from "react"
import { useParams } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { Formik, FormikValues } from "formik"
import * as yup from "yup"
import {
  Dialog,
  DialogDisclosure,
  DialogStateReturn,
  useDialogState,
} from "reakit/Dialog"
import cx from "classnames"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

import { CloseButton } from "../../../components/CloseButton"
import RoiModalContentDesktop from "./RoiModalContentDesktop"
import WhatIsThisNumberModal from "./WhatIsThisNumberModal"
import RoiModalContentMobile from "./RoiModalContentMobile"
import RequestConsultation from "./RequestConsultation"
import { useAccountId, useUpdateRoi } from "../../../hooks"
import useViewport from "../../../hooks/useViewport"
import { CALCULATOR_INPUTS } from "../../../shared/constants"
import { shortenAcreage } from "../../../utils"
import { CalculatorInputsTypes, RoiTypes } from "@/types/roi"

interface RoiModalTypes {
  roiDialog: DialogStateReturn
  roiData: RoiTypes
  isUrgent: boolean
  roiCalcDefaults: CalculatorInputsTypes
}

const validationSchema = yup.object().shape({
  eligible_acres: yup.number().required("Enter in a number"),
  yearly_revenue: yup.number().required("Enter in a number"),
  startup_cost: yup.number().required("Enter in a number"),
  annual_cost: yup.number().required("Enter in a number"),
  inflation_rate: yup.number().required("Enter in a number"),
  discount_rate: yup.number().required("Enter in a number"),
})

const RoiModal = ({
  roiDialog,
  roiData,
  isUrgent,
  roiCalcDefaults,
}: RoiModalTypes) => {
  const queryClient = useQueryClient()
  const { projectId } = useParams()
  const accountId = useAccountId()
  const [isReseting, setIsReseting] = useState(false)
  const { mutateAsync: updateRoi } = useUpdateRoi(
    queryClient,
    accountId,
    projectId as string,
    {}
  )

  const handleSubmit = async (values: FormikValues) => {
    const queryParams = Object.entries(values).reduce((acc, [key, value]) => {
      const typedKey = key as keyof CalculatorInputsTypes

      if (roiCalcDefaults[typedKey] !== value) {
        if (
          key === CALCULATOR_INPUTS.INFLATION_RATE ||
          key === CALCULATOR_INPUTS.DISCOUNT_RATE
        ) {
          acc[typedKey] = value / 100
        } else {
          acc[typedKey] = value
        }
      }
      return acc
    }, {} as Partial<CalculatorInputsTypes>)

    await updateRoi(queryParams as Record<string, string>)
  }

  const handleReset = async (resetForm: () => void) => {
    setIsReseting(true)
    await updateRoi({})
    resetForm()
    setIsReseting(false)
  }

  const whatIsThisNumberDialog = useDialogState({ animated: true })

  const { width, height } = useViewport()
  const isMobile = width < 1024

  const scrollRef = useRef<HTMLDivElement>(null)

  // DEV: Fix dialog body scrolling issue on iOS
  useEffect(() => {
    const scrollBox = scrollRef.current
    if (roiDialog.visible && scrollBox) {
      disableBodyScroll(scrollBox)
    }
    return () => {
      if (scrollBox) {
        enableBodyScroll(scrollBox)
      }
    }
  }, [roiDialog.visible])

  return (
    <Dialog
      {...roiDialog}
      aria-label="Calculate your potential earnings"
      className="dialog-full"
      hideOnEsc={true}
      hideOnClickOutside={false}
    >
      <div>
        <div
          ref={scrollRef}
          // DEV: specify height of the div to be the exact height of the viewport in order to prevent scrolling issues with bottom fixed element on mobile
          style={{ height }}
          className="relative w-full overflow-y-auto flex flex-col sm:justify-between pb-0"
        >
          <div
            className={cx(
              "container xl:max-w-1120 pt-6 pb-32 lg:pb-16 lg:pt-16",
              { "pb-5 sm:pb-16": isUrgent }
            )}
          >
            <div className="flex flex-col-reverse lg:flex-row justify-between gap-4 mb-7 lg:mb-12 lg:px-4">
              <div className="max-w-643 text-center mx-auto">
                <h2 className="flex-auto text-xl leading-120 tracking-0.14 mb-5">
                  Calculate your potential earnings
                </h2>

                <p className="text-base leading-130 tracking-0.32">
                  Discover more details about earnings by exploring the
                  calculator, chart, and table.
                </p>
              </div>

              <CloseButton
                className="shrink-0 justify-self-end self-end lg:self-auto rounded focus:outline-none text-charcoal-500 font-bold lg:absolute lg:top-64 lg:right-64"
                aria-label="Close modal"
                onClick={roiDialog.hide}
              />
            </div>

            <Formik
              enableReinitialize={true}
              initialValues={{
                ...roiData.calculator_inputs,
                [CALCULATOR_INPUTS.ELIGIBLE_ACRES]: shortenAcreage(
                  roiData.calculator_inputs.eligible_acres
                ),
                [CALCULATOR_INPUTS.INFLATION_RATE]:
                  roiData.calculator_inputs.inflation_rate * 100,
                [CALCULATOR_INPUTS.DISCOUNT_RATE]:
                  roiData.calculator_inputs.discount_rate * 100,
              }}
              validationSchema={validationSchema}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={handleSubmit}
            >
              {(formikProps) =>
                isMobile ? (
                  <RoiModalContentMobile
                    roiData={roiData}
                    isMobile={isMobile}
                    width={width - 40}
                    height={(width - 40) * 0.7}
                    formikProps={formikProps}
                    handleReset={handleReset}
                    isReseting={isReseting}
                  />
                ) : (
                  <RoiModalContentDesktop
                    roiData={roiData}
                    whatIsThisNumberDialog={whatIsThisNumberDialog}
                    isMobile={isMobile}
                    formikProps={formikProps}
                    handleReset={handleReset}
                    isReseting={isReseting}
                  />
                )
              }
            </Formik>
          </div>

          {isUrgent ? <RequestConsultation /> : null}

          {isMobile ? (
            <div className="fixed bottom-0 left-0 right-0">
              <div className="bg-white border-t-1 border-t-dusk-50 p-4 h-20">
                <p className="text-base text-dusk leading-130 tracking-0.32 mb-2">
                  <span className="inline-block text-charcoal-500 font-bold mr-2">
                    ${roiData.project_npv.toLocaleString("en-US")}
                  </span>
                  Time Adjusted Value
                </p>

                <DialogDisclosure
                  {...whatIsThisNumberDialog}
                  className="link text-sm font-semibold leading-130 tracking-0.14"
                >
                  What is this number?
                </DialogDisclosure>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <WhatIsThisNumberModal dialog={whatIsThisNumberDialog} />
    </Dialog>
  )
}

export default RoiModal
