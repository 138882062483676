import { QueryClient, UseQueryResult } from "@tanstack/react-query"
import { requestTeaserAssessment } from "../../../api/data"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"
import {
  Feature,
  Geometry,
  GeometryCollection,
  Properties,
} from "@turf/helpers"

import { useQueryWithRefresh } from "../utils/useQueryWithRefresh"

interface DataTypes {
  features: Feature<Geometry | GeometryCollection, Properties>[]
}

export function useTeaserAssessment<TData, TError>(
  queryClient: QueryClient,
  data: DataTypes,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> {
  return useQueryWithRefresh<TData, TError>(
    queryClient,
    ["assessment-preview", data],
    () => requestTeaserAssessment(data),
    config
  )
}
