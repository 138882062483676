import { useNavigate } from "react-router-dom"

import { ActionPermissionWrapper } from "../../components/ActionPermissionWrapper"
import { ContentCard } from "../../components/Card"
import { useAccountUrlPrefix } from "../../hooks"
import { AccountRoleType } from "../../types/account"

interface BookAnExpertConsultationProps {
  accountRole: AccountRoleType
}

const BookAnExpertConsultation = ({
  accountRole,
}: BookAnExpertConsultationProps) => {
  const navigate = useNavigate()
  const accountUrlPrefix = useAccountUrlPrefix()

  return (
    <ContentCard>
      <h2 className="text-xl md:text-3xl text-autumn font-medium leading-[140%] md:leading-[124%] tracking-0.4 md:tracking-[0.6px]">
        Book an expert consultation
      </h2>

      <p className="leading-[140%] tracking-0.32 mt-4">
        Get a personalized expert review and recommendations for your land.
      </p>

      <ActionPermissionWrapper accountRole={accountRole} action="editAccount">
        <button
          className="dashboard-ask-a-question max-w-40 btn2 btn2-primary font-semibold btn2-block mt-4 mb-0"
          onClick={() => {
            navigate(`${accountUrlPrefix}/advisor-signup`)
          }}
        >
          Learn More
        </button>
      </ActionPermissionWrapper>
    </ContentCard>
  )
}

export default BookAnExpertConsultation
