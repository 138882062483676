import { PROJECT_INTEREST } from "../../shared/constants"
import CheckboxGroup from "../../components/CheckboxGroup"
import { RadioGroup } from "../../components/RadioGroup"
import { ChoiceType } from "@/types"

const questionClassname = "space-y-2 mt-8 overflow-y-auto h-56"

export const formSteps = {
  0: {
    id: "onboarding_goal",
    headerText: "What do you want to do today?",
    subheader: "Select one. This helps us personalize your experience.",
    options: [
      { value: "learn", label: "Learn about Programs" },
      { value: "browse", label: "Browse Programs" },
      { value: "eligibility", label: "Check Program Eligibility" },
      { value: "ncx_expert", label: "Talk with NCX Expert" },
      { value: "project_developer", label: "Talk with Program Developer" },
    ],
    formInput: (options: ChoiceType[]) => (
      <RadioGroup
        name="onboarding_goal"
        className={questionClassname}
        valueContentTuples={options.map((choice: ChoiceType) => [
          choice.value,
          choice.label,
        ])}
        state={options[0] as unknown as string | number | undefined}
        aria-label="Onboarding Goals"
      />
    ),
  },
  1: {
    id: "land_goal",
    headerText: "What is your most important goal?",
    subheader: "Select one. This helps us recommend you programs.",
    options: [
      { value: "wildlife", label: "Wildlife and recreation" },
      { value: "habitat", label: "Habitat restoration" },
      { value: "income", label: "Income generation" },
      { value: "threat_reduction", label: "Threat risk reduction" },
      { value: "preservation", label: "Preservation or conservation" },
      { value: "management_reduction", label: "Reduce management effort" },
      { value: "societal_benefit", label: "Societal benefit" },
      { value: "keep_in_family", label: "Keep land in the family" },
    ],
    formInput: (options: ChoiceType[]) => (
      <RadioGroup
        name="land_goal"
        className={questionClassname}
        valueContentTuples={options.map((choice: ChoiceType) => [
          choice.value,
          choice.label,
        ])}
        state={options[0] as unknown as string | number | undefined}
        aria-label="Land Goals"
      />
    ),
  },
  2: {
    id: "start_program_intention",
    headerText: "When do you want to start a program?",
    subheader: "Select one. This helps us create your land report.",
    options: [
      { value: "0_3_mo", label: "As soon as possible" },
      { value: "3_6_mo", label: "In the next 3-6 months" },
      { value: "6_12_mo", label: "In the next 6-12 months" },
      { value: "unknown", label: "Just Browsing" },
    ],
    formInput: (options: ChoiceType[]) => (
      <RadioGroup
        name="start_program_intention"
        className={questionClassname}
        valueContentTuples={options.map((choice: ChoiceType) => [
          choice.value,
          choice.label,
        ])}
        state={options[0] as unknown as string | number | undefined}
        aria-label="Start Program Timing"
      />
    ),
  },
  3: {
    id: "project_interest",
    headerText: "What programs interest you?",
    subheader:
      "Select any. This helps us share information and recommend programs.",
    options: [],
    formInput: () => (
      <CheckboxGroup
        name="project_interest"
        className={questionClassname}
        valueContentTuples={Object.keys(PROJECT_INTEREST).map((key) => [
          key,
          PROJECT_INTEREST[key as keyof typeof PROJECT_INTEREST],
        ])}
      />
    ),
  },
}
