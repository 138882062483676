import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  MutableRefObject,
  SetStateAction,
} from "react"
import * as Sentry from "@sentry/react"
import MapGL, { NavigationControl, Viewport } from "@urbica/react-map-gl"
// @ts-ignore
import Draw from "@urbica/react-map-gl-draw"
import "mapbox-gl/dist/mapbox-gl.css"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"
import mapboxglSupported from "@mapbox/mapbox-gl-supported"
// @ts-ignore
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode"
import bbox from "@turf/bbox"
import bboxPolygon from "@turf/bbox-polygon"
import booleanIntersects from "@turf/boolean-intersects"
import difference from "@turf/difference"
import { useMount } from "react-use"
import { parseDms } from "dms-conversion/dist/esm/dms"
import { useQueryClient } from "@tanstack/react-query"
import { DialogStateReturn } from "reakit/Dialog"

import { Toast } from "./Toast"
import { AddressAutocomplete } from "./AddressAutocomplete"
import { MapMainMenu } from "./MapMainMenu"
import { LayerPicker } from "./LayerPicker"
import {
  ParcelSelectLayer,
  ParcelSelectMode,
  PARCEL_LAYER_MIN_ZOOM,
} from "../components/ParcelSelectLayer"
import { getAddressLatLng } from "../api/data"
import { fetchParcelData } from "../hooks"
import { wktArrToFeatures } from "../utils"
import mapVisualizationDrawStyles from "../shared/mapVisualizationDrawStyles"
import { MAPBOX_TOKEN, mapStyles } from "../shared/constants"
import { isDMS } from "../shared/utils"
import { LayerType } from "../types/mapbox"
import {
  BBox,
  Feature,
  Geometry,
  GeometryCollection,
  MultiPolygon,
  Polygon,
  Properties,
} from "@turf/helpers"
const unitedStatesOutlinePolygon = import(
  "../fixtures/united-states-outline-polygon.json"
)

type MapGLRef = MutableRefObject<MapGL>
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
type DrawRef = MutableRefObject<Draw | null>

interface MapVisualizationTypes {
  features: Feature<Geometry | GeometryCollection, Properties>[]
  incrementNumParcelRequests?: () => void
  decrementNumParcelRequests?: () => void
  viewport: Viewport
  setViewport: (viewport: Viewport) => void
  onFeatureCreate: (
    features: Feature<Geometry | GeometryCollection, Properties>[]
  ) => void
  onFeatureUpdate: (
    features: Feature<Geometry | GeometryCollection, Properties>[]
  ) => void
  onFeatureDelete: (
    features: Feature<Geometry | GeometryCollection, Properties>[]
  ) => void
  onFeatureClear: () => void
  onViewportChange: (viewport: Viewport) => void
  uploadDialog?: DialogStateReturn
  displayMapEditingTools: boolean
  showParcelSelect: boolean
  showParcelDraw: boolean
  showParcelUpload: boolean
  decodedAddress?: string | null
}

const EMPTY_FEATURE: Feature<Geometry | GeometryCollection, Properties> = {
  type: "Feature",
  geometry: {
    type: "Point",
    coordinates: [],
  },
  properties: {},
}

const WEBGL_NOT_SUPPORTED = "insufficient WebGL support"

function isPolygonOrMultiPolygon(
  feature: Feature<Geometry | GeometryCollection, Properties>
): feature is Feature<Polygon | MultiPolygon, Properties> {
  return (
    feature.geometry.type === "Polygon" ||
    feature.geometry.type === "MultiPolygon"
  )
}

function clipSelectedParcelsFromExistingFeatures({
  features,
  parcelFeatures,
}: {
  features: Feature<Geometry | GeometryCollection, Properties>[]
  parcelFeatures: Feature<Geometry | GeometryCollection, Properties>[]
}): Feature<Geometry | GeometryCollection, Properties>[] {
  return features.map((feature) => {
    let clippedFeature: Feature<
      Geometry | GeometryCollection,
      Properties
    > | null = feature

    for (const parcelFeature of parcelFeatures) {
      if (
        clippedFeature &&
        isPolygonOrMultiPolygon(clippedFeature) &&
        isPolygonOrMultiPolygon(parcelFeature)
      ) {
        if (booleanIntersects(clippedFeature, parcelFeature)) {
          clippedFeature = difference(clippedFeature, parcelFeature) as Feature<
            Geometry | GeometryCollection,
            Properties
          > | null
        }
      }
    }

    if (clippedFeature === null) {
      clippedFeature = { ...EMPTY_FEATURE, id: feature.id }
    } else {
      clippedFeature.id = feature.id
    }

    return clippedFeature
  })
}

export const MapboxGLNotSupported = ({ reason }: { reason: string }) => (
  <div className="bg-gray2 w-full h-full">
    <div className="w-1/2 mx-auto mt-1/4 bg-white p-12">
      {reason === WEBGL_NOT_SUPPORTED ? (
        <>
          WebGL is required to view your property boundaries on a map. Your
          browser does not support WebGL, or WebGL is currently disabled. For
          help, visit{" "}
          <a
            className="link"
            href="https://get.webgl.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://get.webgl.org/
          </a>
        </>
      ) : (
        <>
          Mapbox GL is required to view your property boundaries on a map. Your
          browser does not support Mapbox GL due to {reason}.
        </>
      )}
    </div>
  </div>
)

export const MapVisualization = forwardRef<MapGL, MapVisualizationTypes>(
  (
    {
      features,
      incrementNumParcelRequests,
      decrementNumParcelRequests,
      viewport,
      setViewport,
      onFeatureCreate,
      onFeatureUpdate,
      onFeatureDelete,
      onFeatureClear,
      onViewportChange,
      showParcelSelect,
      showParcelDraw,
      showParcelUpload,
      uploadDialog,
      decodedAddress,
      displayMapEditingTools,
    },
    ref
  ) => {
    const mapRef = ref as MapGLRef
    const queryClient = useQueryClient()
    const mapContainerRef = useRef<HTMLDivElement | null>(null)
    const drawRef: DrawRef = useRef(null)
    const [mode, setMode] = useState<string>("simple_select")
    const [modeOptions, setModeOptions] = useState({})
    const [parcelSelectState, setParcelSelectState] = useState({})
    const [selectedFeatures, setSelectedFeatures] = useState<Feature[]>([])
    const [search, setSearch] = useState("")
    const [layer, setLayer] = useState<LayerType>("aerial")

    // https://github.com/mapbox/mapbox-gl-draw/blob/v1.2.0/docs/MODES.md
    // Define a custom file upload mode so that we can control the isActive
    // status of the File Upload MapMainMenuButton
    const FileUploadMode = {
      onSetup: function (_opts: any) {
        return {}
      },
      toDisplayFeatures: function (
        _opts: any,
        geojson: any,
        display: (geojson: any) => void
      ) {
        display(geojson)
      },
    }

    const handleSearchSelect = async (address: string) => {
      if (address?.trim() !== "") {
        try {
          const { lat, lng } = await getAddressLatLng(address)
          setViewport({
            latitude: lat,
            longitude: lng,
            zoom: 15,
          })
          setSearch(address)
        } catch (error) {
          if (typeof error === "string") {
            // https://developers.google.com/maps/documentation/javascript/places#place_details_responses
            if (error === "ZERO_RESULTS") {
              // abbrs.: DMS (Degrees/Minutes/Seconds), DD (Decimal Degrees)
              // at this point, it's either no results because input
              // does not have a matching address, or DMS format is entered

              // if input value is in DMS, convert to DD and perform another search
              if (isDMS(address)) {
                const DMSToDD = address
                  .split(",")
                  .map((item) => parseDms(item.trim()))
                  .join(",")

                handleSearchSelect(DMSToDD)
              } else {
                // if input value is not DMS, then display error message
                Toast.error("The address you entered could not be found.", 2500)
              }
              return
            } else if (
              // "ERROR" not documented but it exists: https://sentry.io/organizations/silviaterra/issues/2366387495/
              ["ERROR", "OVER_QUERY_LIMIT", "UNKNOWN_ERROR"].includes(error)
            ) {
              Toast.error(
                "Unable to look up address at this time. Try again later.",
                2500
              )
              return
            }
            // Upcast error string to a stacktraceable error for Sentry
            //   "Non-Error promise rejection captured with value:", https://sentry.io/organizations/silviaterra/issues/2352865303/
            throw new Error("Error in handleSearchSelect: " + error)
          } else {
            throw error
          }
        }
      }
    }

    const handleDrawDelete = (payload: {
      features: Feature<Geometry | GeometryCollection, Properties>[]
    }) => {
      if (drawRef.current) {
        setSelectedFeatures(
          drawRef.current.getDraw().getSelected().features as Feature[]
        )
      }
      // @ts-ignore
      onFeatureDelete(payload.features)
    }

    const handleDrawCreate = (payload: {
      features: Feature<Geometry | GeometryCollection, Properties>[]
    }) => {
      onFeatureCreate(payload.features)
    }

    const handleDrawUpdate = (payload: {
      features: Feature<Geometry | GeometryCollection, Properties>[]
    }) => {
      onFeatureUpdate(payload.features)
    }

    // DEV: This only fires from draw specific events
    //   https://github.com/urbica/react-map-gl-draw/blob/v0.3.5/src/components/Draw/index.js#L174-L188
    const handleDrawModeChange = ({ mode }: { mode: string }) => {
      if (mode === "direct_select") {
        return
      }
      setMode(mode)
    }

    const handleDrawSelectionChange = async (data: {
      reactState: SetStateAction<Record<string, never>>
      parcelId: string
      action: string
      features: SetStateAction<
        Feature<Geometry | GeometryCollection, Properties>[]
      >
    }) => {
      if (mode === "parcel_select") {
        // data = { action, parcelId, reactState }, defined by "draw.selectionchange" in `ParcelSelectLayer`
        //   also { target, type }, provided by <Draw>
        // DEV: We lose Sentry scope from `ParcelSelectLayer` due to <Draw> running this as a callback, so rebuild them
        // DEV: We need to use `try/catch` + report as we'll return to outer scope execution (thus losing newly pushed scope)
        try {
          setParcelSelectState(data.reactState)
          const selectedParcelIds = [data.parcelId]
          incrementNumParcelRequests?.()

          if (data.action === "add") {
            await handleParcelAdd(selectedParcelIds)
          } else if (data.action === "delete") {
            await handleParcelDelete(selectedParcelIds)
          } else {
            throw new Error(`Unrecognized action ${data.action}`)
          }

          decrementNumParcelRequests?.()
        } catch (err) {
          Sentry.getCurrentScope().setContext("parcel_select", {
            action: data.action,
            parcelId: data.parcelId,
          })
          throw err
        }
        // DEV: We would be running a `data.onSuccess()` handler here (hence await's)
        //   but timing of React scheduler + MapBox GL requestAnimationFrame lead to inconsistencies
        //   See further explanation at `onSuccessCallbacks.push` in `ParcelSelectLayer`
      } else {
        // features is an array of geojson features
        setSelectedFeatures(data.features)
      }
    }

    // When different map menu buttons should be displayed
    const showMenuSelect = () =>
      mode === "simple_select" &&
      features?.length !== 0 &&
      selectedFeatures.length === 0
    const showMenuParcelSelect = () =>
      mode === "simple_select" &&
      selectedFeatures.length === 0 &&
      showParcelSelect
    const showMenuDrawPolygon = () =>
      mode === "simple_select" &&
      selectedFeatures.length === 0 &&
      showParcelDraw
    const showMenuFileUpload = () =>
      mode === "simple_select" &&
      selectedFeatures.length === 0 &&
      showParcelUpload
    const showMenuEdit = () => selectedFeatures.length !== 0
    const showMenuTrash = () => selectedFeatures.length !== 0
    const showMenuBack = () =>
      mode !== "simple_select" || selectedFeatures.length !== 0
    const showMenuClear = () =>
      mode === "simple_select" &&
      features?.length !== 0 &&
      selectedFeatures.length === 0

    const handleMenuSelect = () => {
      setMode("simple_select")
      setModeOptions({})
    }

    const checkIfViewportInUSA = async () => {
      const unitedStatesOutline = await unitedStatesOutlinePolygon

      const map = mapRef.current?.getMap()
      const viewportBbox = bboxPolygon(map.getBounds().toArray().flat() as BBox)

      if (
        !unitedStatesOutline.features.some((feature) =>
          booleanIntersects(
            feature as Feature<Geometry | GeometryCollection, Properties>,
            viewportBbox
          )
        )
      ) {
        Toast.error(
          "This search area is outside of the NCX program area - we are not available outside the contiguous United States. Sorry!"
        )
      }

      map.off("zoomend", checkIfViewportInUSA)
    }

    const handleMenuParcelSelect = () => {
      const map = mapRef.current?.getMap()
      if (map && map.getZoom() < PARCEL_LAYER_MIN_ZOOM) {
        map.zoomTo(PARCEL_LAYER_MIN_ZOOM, {
          duration: 1000 * (PARCEL_LAYER_MIN_ZOOM - map.getZoom()),
        })
      }

      map.on("zoomend", checkIfViewportInUSA)

      setMode("parcel_select")
      setModeOptions({
        reactState: parcelSelectState,
      })
    }

    const handleParcelAdd = async (selectedParcelIds: string[]) => {
      const parcelWktArr = await fetchParcelData(queryClient, selectedParcelIds)
      const features = wktArrToFeatures(parcelWktArr)
      handleDrawCreate({ features: features })
    }

    const handleParcelDelete = async (selectedParcelIds: string[]) => {
      const parcelWktArr = await fetchParcelData(queryClient, selectedParcelIds)
      const parcelFeatures = wktArrToFeatures(parcelWktArr)
      const updatedFeatures = clipSelectedParcelsFromExistingFeatures({
        features,
        parcelFeatures,
      })
      handleDrawUpdate({ features: updatedFeatures })
    }

    const handleMenuDrawPolygon = () => {
      checkIfViewportInUSA()
      setModeOptions({})
      setMode("draw_polygon")
    }

    const handleMenuEdit = () => {
      setMode("direct_select")
      setModeOptions({ featureId: selectedFeatures[0].id })
    }

    const handleMenuTrash = () => drawRef.current?.getDraw()?.trash()

    const handleMenuBack = () => {
      // Reset is a dummy mode that's the same as simple_select. The problem is
      // if the user is already in simple_select mode and has a feature selected,
      // clicking the Back button won't deselect the feature if we set the mode
      // directly to simple_select. Setting to "reset" instead will register as a
      // mode change in MapboxDraw and do the proper cleanup (deselecting features).
      setMode(mode === "simple_select" ? "reset" : "simple_select")
      setModeOptions({})
      setSelectedFeatures([])
    }

    const handleMenuFileUpload = () => {
      setMode("file_upload")
      if (uploadDialog) {
        uploadDialog.show()
      }
    }

    useMount(() => {
      if (mapRef.current) {
        const map = mapRef.current.getMap()

        if (features.length > 0) {
          map.fitBounds(
            bbox({
              type: "FeatureCollection",
              features,
            }),
            {
              padding: 40,
            }
          )
        }
      }
    })

    useEffect(() => {
      // If clear bounds is clicked, reset selected features
      if (features?.length === 0) {
        setSelectedFeatures([])
      }
    }, [features])

    useEffect(() => {
      if (decodedAddress) {
        setSearch(decodedAddress)
        // set the parcel select mode
        setMode("parcel_select")
        setModeOptions({
          reactState: parcelSelectState,
        })
      }
    }, [decodedAddress, setSearch, parcelSelectState])

    useEffect(() => {
      const map = mapRef.current?.getMap()
      if (map) {
        if (mode === "draw_polygon") {
          map.dragPan.disable()
        } else {
          map.dragPan.enable()
        }
      }

      // See handleMenuBack above for why there is a reset mode.
      // It's a proxy for going back to simple_select mode, so we set it back here.
      if (mode === "reset") {
        setMode("simple_select")
      }
    }, [mapRef, mode])

    // DEV: focus on map when map is open for accessibility purposes
    useEffect(() => {
      if (
        mapContainerRef.current &&
        typeof mapContainerRef.current !== "undefined"
      ) {
        mapContainerRef.current.focus()
      }
    }, [])

    if (mapboxglSupported.supported()) {
      return (
        // DEV: Use `flex-col` to avoid Safari not occupying full vertical height, though doesn't seem to repro like Auth layout did */}
        <div
          ref={mapContainerRef}
          tabIndex={0}
          className="map-vizualization relative flex-auto flex flex-col"
          aria-label="Map"
        >
          <MapGL
            ref={mapRef}
            pitchWithRotate={false}
            dragRotate={false}
            className="flex-auto"
            mapStyle={mapStyles[layer].url}
            accessToken={MAPBOX_TOKEN}
            onViewportChange={onViewportChange}
            latitude={viewport.latitude}
            longitude={viewport.longitude}
            zoom={viewport.zoom}
            showTileBoundaries={false}
            maxZoom={17}
          >
            {mode === "parcel_select" && (
              <ParcelSelectLayer
                mapRef={mapRef}
                layer={layer}
                features={features}
              />
            )}
            {/* DEV: Draw must always be rendered to properly load `ParcelSelectMode` */}
            <Draw
              ref={drawRef}
              data={{
                type: "FeatureCollection",
                features: mode === "parcel_select" ? [] : features,
              }}
              styles={mapVisualizationDrawStyles}
              mode={!displayMapEditingTools ? "static" : mode}
              modeOptions={modeOptions}
              modes={(defaultModes: { [x: string]: any }) => ({
                ...defaultModes,
                parcel_select: ParcelSelectMode,
                file_upload: FileUploadMode,
                reset: defaultModes["simple_select"],
                static: StaticMode,
              })}
              lineStringControl={false}
              polygonControl={false}
              pointControl={false}
              combineFeaturesControl={false}
              uncombineFeaturesControl={false}
              trashControl={false}
              onDrawSelectionChange={handleDrawSelectionChange}
              onDrawDelete={handleDrawDelete}
              onDrawCreate={handleDrawCreate}
              onDrawUpdate={handleDrawUpdate}
              onDrawModeChange={handleDrawModeChange}
            />
            <NavigationControl
              showCompass={false}
              showZoom
              position="top-right"
            />
          </MapGL>
          {/* DEV: We don't use any top-bar <div> as it would block click actions at top of map, despite transparent bg */}
          {displayMapEditingTools && (
            <MapMainMenu
              className="absolute top-20 left-4 md:top-4 lg:top-12 lg:left-12"
              mode={mode}
              isSelectVisible={showMenuSelect()}
              isParcelSelectVisible={showMenuParcelSelect()}
              isDrawPolygonVisible={showMenuDrawPolygon()}
              isFileUploadVisible={showMenuFileUpload()}
              isEditVisible={showMenuEdit()}
              isTrashVisible={showMenuTrash()}
              isBackVisible={showMenuBack()}
              isClearVisible={showMenuClear()}
              onClickSelect={handleMenuSelect}
              onClickParcelSelect={handleMenuParcelSelect}
              onClickDrawPolygon={handleMenuDrawPolygon}
              onClickFileUpload={handleMenuFileUpload}
              onClickEdit={handleMenuEdit}
              onClickTrash={handleMenuTrash}
              onClickBack={handleMenuBack}
              onClickClear={onFeatureClear}
            />
          )}
          {/* DEV: inset-x-0 sets left/right: 0 which combined with mx-auto centers absolutely */}
          {/*   https://css-tricks.com/forums/topic/horizontal-centering-of-an-absolute-element/ */}
          {typeof google?.maps?.places !== "undefined" ? (
            <div className="absolute xs:max-w-[356px] top-4 lg:top-12 inset-x-4 md:inset-x-0 md:mx-auto">
              <AddressAutocomplete
                className="bg-white rounded"
                value={search}
                onChange={setSearch}
                onSelect={(address) => {
                  handleSearchSelect(address)
                }}
                onError={() => {}}
              />
            </div>
          ) : null}
          {/* DEV: Using div wrapper for LayerPicker as LayerPicker is using `absolute` and we can't guarantee CSS order */}
          <div className="absolute md:top-28 md:right-4 lg:top-36 lg:right-12 hidden md:block">
            <LayerPicker
              layer={layer}
              onLayerChange={(layer) => {
                setLayer(layer)
                // urbica react-map-gl does not support creating a map initially in direct_select mode,
                // because this mode requires a featureId to be provided as `modeOptions`, and the MapGL
                // component doesn't accept `modeOptions` on creation.
                // A layer change will cause a rerender of the MapGL component, so reset the mode if it
                // is currently direct_select.
                if (mode === "direct_select") {
                  setMode("reset")
                }
              }}
            />
          </div>
        </div>
      )
    } else {
      const reason = mapboxglSupported.notSupportedReason()
      try {
        return <MapboxGLNotSupported reason={reason} />
      } finally {
        if (reason !== WEBGL_NOT_SUPPORTED) {
          throw new Error("Mapbox GL unsupported - " + reason)
        }
      }
    }
  }
)
