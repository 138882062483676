import { Form, Formik } from "formik"
import * as Yup from "yup"

import { WrappedInput } from "../../components/Input"
import { SubmitButton } from "../../components/SubmitButton"
import { Toast } from "../../components/Toast"
import { useInviteYourNeighbor } from "../../hooks"
import { genericErrMsg } from "../../api/auth"

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please provide a valid email address.")
    .required("Please provide your neighbor's email address."),
})

const InviteYourNeighbor = () => {
  const { mutateAsync: inviteYourNeighbor } = useInviteYourNeighbor({
    onSuccess: () => {
      Toast.success("Thanks! We'll send them an invite.")
    },
    onError: (error) => {
      Toast.error(error?.detail || genericErrMsg)
    },
  })

  const handleSubmit = async (values) => {
    await inviteYourNeighbor(values)
  }

  return (
    <div className="card card-content px-6 pt-6 pb-4 mb-6">
      <h4 className="text-overline text-dusk-500 mb-3">Invite your neighbor</h4>

      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form className="inline">
            <div className="mb-3">
              <WrappedInput
                label="Enter an email address to invite fellow landowners to join NCX."
                labelClass="leading-18px font-normal"
                name="email"
                as="email"
                placeholder="example@email.com"
                className="mt-2 resize-none border-charcoal-50"
                aria-label="Invite your neighbor email input"
              />
            </div>

            <SubmitButton
              className="btn2 btn2-primary font-semibold w-full"
              isSubmitting={formikProps.isSubmitting}
            >
              Invite to Join NCX
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default InviteYourNeighbor
