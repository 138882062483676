/* eslint-disable react/no-unstable-nested-components */
import { Form, FormikProps } from "formik"
import cx from "classnames"

import { WrappedInput } from "../../../components/Input"
import { ButtonPair } from "../../../components/ButtonPair"
import { SubmitButton } from "../../../components/SubmitButton"
import { CALCULATOR_INPUTS } from "../../../shared/constants"
import { CalculatorInputsTypes } from "@/types/roi"

interface RoiCalculatorTypes {
  formikProps: FormikProps<CalculatorInputsTypes>
  handleReset: (resetForm: () => void) => Promise<void>
  isReseting: boolean
}

const RoiCalculator = ({
  formikProps,
  handleReset,
  isReseting,
}: RoiCalculatorTypes) => (
  <div className="w-full lg:w-304">
    <Form className="">
      <div className="mb-3">
        <WrappedInput
          label="Eligible Acres"
          labelClass="mb-2"
          aria-label="Eligible Acres"
          type="number"
          step="any"
          name={CALCULATOR_INPUTS.ELIGIBLE_ACRES}
        />
      </div>
      <div className="mb-3">
        <WrappedInput
          label="Yearly revenue (per acre)"
          labelClass="mb-2"
          aria-label="Yearly Revenue (per acre)"
          type="number"
          step="any"
          name={CALCULATOR_INPUTS.YEARLY_REVENUE}
          prefix="$"
        />
      </div>
      <div className="mb-3">
        <WrappedInput
          label="Startup Cost"
          labelClass="mb-2"
          aria-label="Startup Cost"
          type="number"
          step="any"
          name={CALCULATOR_INPUTS.STARTUP_COST}
          prefix="$"
        />
      </div>
      <div className="mb-3">
        <WrappedInput
          label="Annual Cost (per acre)"
          labelClass="mb-2"
          aria-label="Annual Cost"
          type="number"
          step="any"
          name={CALCULATOR_INPUTS.ANNUAL_COST}
          prefix="$"
        />
      </div>
      <div className="mb-3">
        <WrappedInput
          label="Inflation Rate"
          labelClass="mb-2"
          aria-label="Inflation Rate"
          type="number"
          step="any"
          name={CALCULATOR_INPUTS.INFLATION_RATE}
          prefix="%"
        />
      </div>
      <div>
        <WrappedInput
          label="Discount Rate"
          labelClass="mb-2"
          aria-label="Discount Rate"
          type="number"
          step="any"
          name={CALCULATOR_INPUTS.DISCOUNT_RATE}
          prefix="%"
        />
      </div>

      <div>
        <ButtonPair
          className="mx-auto sm:mx-0 mt-6 w-full"
          primary={(primaryProps) => (
            <SubmitButton
              className={cx(
                primaryProps.className,
                "sm:!w-1/2 roi-calculator-calculate"
              )}
              isSubmitting={formikProps.isSubmitting}
            >
              Calculate
            </SubmitButton>
          )}
          secondary={(secondaryProps) => (
            <SubmitButton
              className={cx(
                secondaryProps.className,
                "sm:!w-1/2 bg-cloud-50 text-base !font-bold py-7px px-2 roi-calculator-reset"
              )}
              typeButton
              isSubmitting={isReseting}
              onClick={() => {
                handleReset(formikProps.resetForm)
              }}
            >
              Reset
            </SubmitButton>
          )}
        />
      </div>
    </Form>
  </div>
)

export default RoiCalculator
