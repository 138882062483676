import { createContext, ReactNode, useContext, useMemo } from "react"
import cx from "classnames"
import {
  useTabState,
  Tab as BaseTab,
  TabList as BaseTabList,
  TabPanel as BaseTabPanel,
  TabStateReturn,
} from "reakit/Tab"

import CommunityQA from "./CommunityQA"

interface TabsContextType extends TabStateReturn {}

interface TabTypes {
  children: ReactNode
  id: string
}

interface TabListTypes {
  children: ReactNode
}

interface TabPanelTypes {
  children: ReactNode
}

interface ProjectDetailsTabsTypes {
  profileId: number
}

const TabsContext = createContext<TabsContextType | undefined>(undefined)

const Tab = ({ children, ...props }: TabTypes) => {
  const tab = useContext(TabsContext)

  return (
    <BaseTab
      className="border-r-1 border-cloud-700 min-w-32 focus:outline-none focus:ring-0"
      {...tab}
      {...props}
    >
      <div
        className={cx(
          "text-base font-bold leading-130 tracking-0.32 normal-case px-6 pt-4 pb-2",
          props.id === tab?.selectedId
            ? "text-white bg-grass-500 border-b-8 border-grass-900"
            : "text-grass-500 border-b-8 border-transparent"
        )}
      >
        {children}
      </div>
    </BaseTab>
  )
}

const TabList = (props: TabListTypes) => {
  const tab = useContext(TabsContext)

  return (
    <BaseTabList
      {...tab}
      {...props}
      className="border border-cloud-700 rounded-t overflow-hidden"
    />
  )
}

const TabPanel = (props: TabPanelTypes) => {
  const tab = useContext(TabsContext)

  return (
    <BaseTabPanel
      {...tab}
      {...props}
      className="border border-t-0 border-cloud-700 rounded-b p-4 focus:ring-0"
    />
  )
}

const ProjectDetailsTabs = ({ profileId }: ProjectDetailsTabsTypes) => {
  const tab = useTabState({ selectedId: "1" })
  const value = useMemo(() => tab, [tab])

  return (
    <div className="container xl:max-w-1120 lg:mb-14 mt-[66px]">
      <hr className="w-full-40 sm:w-full border-charcoal-50 mx-5 sm:mx-0 mb-12" />

      <TabsContext.Provider value={value}>
        <div className="w-full lg:max-w-552 mt-12 mb-10">
          <TabList aria-label="Program detail tabs">
            <Tab id="1">Community Q & A</Tab>
          </TabList>

          <TabPanel>
            <CommunityQA profileId={profileId} />
          </TabPanel>
        </div>
      </TabsContext.Provider>
    </div>
  )
}

export default ProjectDetailsTabs
