import React, { ReactNode, useContext } from "react"
import { Navigate, useLocation, useSearchParams } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import cx from "classnames"

import { Spinner } from "../../components/Spinner"
import {
  useAccount,
  useAccountId,
  useCreateMembership,
  useProfile,
  useAccountProperty,
  useAccountUrlPrefix,
  useIsMultiAccount,
} from "../../hooks"
import { useAccessToken } from "../../stores"
import { VerifyEmailInnerContent } from "../../sections/VerifyEmailContent"
import AcceptTocModal from "../../sections/AcceptTocModal"
import { NavLayoutContext } from "../../context/NavLayoutContext"
import { AccountProperty } from "../../types/property"
import { Profile, VerifyUserEmailVariant } from "../../types"
import { AccountTypes } from "@/types/account"

interface MainLayoutProps {
  children: ReactNode
  isLoading?: boolean | boolean[]
  contentBg?: string
  loader?: ReactNode
  className?: string
  verifyUserEmailVariant?: VerifyUserEmailVariant
}

const MainLayout = ({
  children,
  isLoading,
  contentBg,
  loader,
  className,
  verifyUserEmailVariant,
}: MainLayoutProps) => {
  const bgClass = `bg-${contentBg}`
  const accountId = useAccountId()
  const isMultiAccount = useIsMultiAccount()
  const accountUrlPrefix = useAccountUrlPrefix()
  const location = useLocation()
  const accessToken = useAccessToken()
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const isLoggedIn = !!accessToken
  const { data: profile, isLoading: profileIsLoading } = useProfile<
    Profile,
    Error
  >(queryClient, {
    enabled: isLoggedIn,
  })

  const membershipSuccessToken = searchParams.get("checkout-success")
  const { status } = useCreateMembership(
    accountId,
    // slice this because stripe sends it over with byte string syntax for some reason
    membershipSuccessToken?.slice(2, membershipSuccessToken?.length - 1) || "",
    {
      enabled: !!accountId && !!membershipSuccessToken,
    }
  )
  const createMembershipIsLoading =
    !!membershipSuccessToken && status === "pending"

  const queryEnabled =
    isLoggedIn &&
    ((isMultiAccount && accountId !== "_single") || !isMultiAccount)

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId, {
    enabled: queryEnabled,
  })
  const { data: property, isLoading: propertyIsLoading } = useAccountProperty<
    AccountProperty,
    Error
  >(queryClient, accountId, {
    enabled: queryEnabled,
  })

  const navLayoutContext = useContext(NavLayoutContext)

  if (!navLayoutContext) {
    throw new Error("NavLayoutContext is not provided")
  }

  const { ncxLogoRef, notificationsIsLoading } = navLayoutContext

  let content
  const isLoadingArr = [
    profileIsLoading,
    notificationsIsLoading,
    accountIsLoading,
    propertyIsLoading,
    createMembershipIsLoading,
    ...(Array.isArray(isLoading) ? isLoading : [isLoading]),
  ]

  // DEV: `verifyEmailStatus` can be "error", but that's valid (i.e. don't want error page) so only block in "pending"
  if (isLoadingArr.includes(true)) {
    content = loader ? (
      loader
    ) : (
      <div className="flex-auto flex items-center justify-center">
        <Spinner />
      </div>
    )
  } else {
    // DEV: 404 pages use Main layout but when logged out, shouldn't render verification content, https://app.asana.com/0/1199976942355619/1202842357806152/f
    content = isLoggedIn ? (
      <>
        <VerifyEmailInnerContent
          profile={profile as Profile}
          verifyUserEmailModalFinalFocusRef={ncxLogoRef}
          verifyUserEmailVariant={verifyUserEmailVariant}
        >
          {children}
        </VerifyEmailInnerContent>
        <AcceptTocModal profile={profile} />
      </>
    ) : (
      children
    )
  }

  if (
    account &&
    property &&
    !property?.bounds?.coordinates?.length &&
    !location.pathname.includes("/property-boundaries") &&
    location.pathname !== "/logout"
  ) {
    return (
      <Navigate
        replace
        to={
          isMultiAccount
            ? `${accountUrlPrefix}/settings/property-boundaries`
            : "/onboarding/property-boundaries"
        }
      />
    )
  }

  return (
    <div className={cx("flex-auto flex flex-col", bgClass, className)}>
      {content}
    </div>
  )
}

export default MainLayout
