import TREE_PLANTING from "../../images/eligibility-quiz-tree_planting.webp"
import FOREST_CARBON from "../../images/eligibility-quiz-forest_carbon.webp"
import RECREATION from "../../images/eligibility-quiz-recreation.webp"
import REGEN_AG from "../../images/eligibility-quiz-regen_ag.webp"
import RENEWABLE_INFRASTRUCTURE from "../../images/eligibility-quiz-renewable_infrastructure.webp"
import WILDFIRE from "../../images/eligibility-quiz-wildfire.webp"
import TIMBER from "../../images/eligibility-quiz-timber.webp"
import ADVISORY_SERVICES from "../../images/eligibility-quiz-advisory_services.webp"
import GENERAL from "../../images/eligibility-quiz-general.webp"
import { EligibilityQuizCategoryType } from "@/types/constants"

export const eligibilityQuizBackground = (
  category: EligibilityQuizCategoryType | undefined
) => {
  const categories = {
    tree_planting: TREE_PLANTING,
    forest_carbon: FOREST_CARBON,
    recreation: RECREATION,
    regen_ag: REGEN_AG,
    renewable_infrastructure: RENEWABLE_INFRASTRUCTURE,
    wildfire: WILDFIRE,
    timber: TIMBER,
    advisory_services: ADVISORY_SERVICES,
    default: GENERAL,
  }

  return categories[category as keyof typeof categories] || categories.default
}
