import assert from "assert"
import { DateTime } from "luxon"
import { saveAs } from "file-saver"
import { toHtml, icon } from "@fortawesome/fontawesome-svg-core"
import Persona from "persona"
import { flatten, find } from "lodash"
import bbox from "@turf/bbox"

import { parseDms } from "dms-conversion/dist/esm/dms"

import { Toast } from "../components/Toast"
import { getIdentityVerificationToken } from "../api/data"
import {
  ACCESS_INFO_CYCLE_START,
  ACCESS_INFO_CYCLE_CLOSE,
  ACCESS_INFO_PERIOD_BY_CYCLE,
  DATETIME_MONTH_DAY_YEAR,
  REVIEW_PERIOD_DURATION,
  DATETIME_TIMEZONE,
  ACCOUNT_CYCLE_STATUS_LIST,
  LANDOWNER_STATUS,
  LANDOWNER_STATUS_MESSAGE,
  PROJECT_BADGE_TYPES,
  PROJECT_SORTING,
  DEADLINE_TYPE,
  PROJECT_TYPES,
} from "./constants"
import {
  stateOptions,
  stateAbbreviations,
  conus,
  stateAbbreviationPattern,
  stateNamePattern,
} from "../fixtures"
import PLACEHOLDER from "../images/project-details.webp"

export const str2bool = (value) => {
  if (value && typeof value === "string") {
    if (value.toLowerCase() === "true") return true
    if (value.toLowerCase() === "false") return false
  }
  return value
}

export const bool2str = (value) => {
  if (value === "") {
    return value
  }
  if (value) {
    return "Yes"
  } else {
    return "No"
  }
}

export const isFunction = (value) => typeof value === "function"

export const generateId = () => Math.random().toString(36).substr(2, 9)

// https://github.com/acdlite/recompose/blob/master/src/packages/recompose/utils/omit.js
/**
 * Returns new object without specified keys.
 * @param {Object} obj
 * @param {string[]} keys
 * @returns {Object}
 */
export const omit = (obj, keys) => {
  const { ...rest } = obj
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    if (Object.prototype.hasOwnProperty.call(rest, key)) {
      delete rest[key]
    }
  }

  return rest
}

export const pluralize = (value, count) => {
  if (count === 1) {
    return value
  }

  return `${value}s`
}

export const anyToDateTime = (input) => {
  if (DateTime.isDateTime(input)) {
    return input
  } else if (typeof input === "string") {
    return DateTime.fromISO(input)
  } else if (typeof input === "number") {
    return DateTime.fromMillis(input)
  } else if (input instanceof Date) {
    return DateTime.fromJSDate(input)
  } else {
    throw new Error(
      `Expected Luxon DateTime, ISO timestamp, milliseconds, or Date, but received: ${input}`
    )
  }
}

export const formatLongTimestamp = (input) => {
  return anyToDateTime(input)
    .setZone(DATETIME_TIMEZONE)
    .toFormat(DATETIME_MONTH_DAY_YEAR)
}

export const getRRDeliveryDateStr = (accountCycle, options) => {
  const format = options?.format || DATETIME_MONTH_DAY_YEAR
  return DateTime.fromISO(accountCycle.rr_document.created_at)
    .setZone(DATETIME_TIMEZONE)
    .toFormat(format)
}

export const getReviewRequestPeriodEndDate = (accountCycle) => {
  // DEV: We could/should receive this from the API to ensure data consistency
  //   However, this is "good enough". See https://app.asana.com/0/0/1202026957474220/f for more info
  return DateTime.fromISO(accountCycle.rr_document.created_at)
    .setZone(DATETIME_TIMEZONE)
    .plus(REVIEW_PERIOD_DURATION)
}
export const getReviewRequestPeriodEndDateStr = (accountCycle, options) => {
  const format = options?.format || DATETIME_MONTH_DAY_YEAR
  return getReviewRequestPeriodEndDate(accountCycle).toFormat(format)
}

export const getItemFromLocalStorage = (key, conversionFunc = JSON.parse) => {
  const item = window.localStorage.getItem(key)
  if (item !== undefined) {
    return conversionFunc(item)
  }
}

export const downloadJSZipObject = async (file) => {
  const blob = await file.async("blob")
  saveAs(blob, file.name)
}

export const getQueryParam = (location, key) => {
  const searchParams = new URLSearchParams(location.search)
  // when no param, returns null
  return searchParams.get(key)
}

export const getAccessInfoPeriodForCycle = (cycleKey) => {
  const accessInfoOverride = getQueryParam(window.location, "allow_access_info")
  if (accessInfoOverride) {
    return [ACCESS_INFO_CYCLE_START, ACCESS_INFO_CYCLE_CLOSE].includes(
      accessInfoOverride
    )
      ? accessInfoOverride
      : ACCESS_INFO_CYCLE_START
  }
  return ACCESS_INFO_PERIOD_BY_CYCLE[cycleKey]
}

export const getAccountCycleStatusIndex = (status) => {
  const index = ACCOUNT_CYCLE_STATUS_LIST.indexOf(status)
  assert.notEqual(index, -1, `Cannot find index for ${status}`)
  return index
}

export const accountCycleStatusIsAtLeast = (accountCycle, targetStatus) => {
  const accountCycleStatusIndex = getAccountCycleStatusIndex(
    accountCycle.status
  )
  const targetStatusIndex = getAccountCycleStatusIndex(targetStatus)
  return accountCycleStatusIndex >= targetStatusIndex
}

export const hasSellingProcessAccountCycle = ({ accountCycles }) => {
  return accountCycles.some((accountCycle) =>
    // assessed = SellingProcessStep1 to 4 normally
    // ncapx_sale_agreement_sent = SellingProcessStep1 to 4 after sending email ("not an authorized signer" flow)
    //   https://app.asana.com/0/1198952737412966/1202624857128805/f
    // ncapx_sale_agreement_signed = SellingProcessStep1 to 4 after DocuSign signed
    [
      "assessed",
      "ncapx_sale_agreement_sent",
      "ncapx_sale_agreement_signed",
    ].includes(accountCycle.status)
  )
}

export const getEnrollmentDeadline = (type, deadline) => {
  const types = {
    specific_date: `Enroll by ${DateTime.fromISO(deadline).toLocaleString({
      month: "short",
      day: "numeric",
      year: "numeric",
    })}`,
    open_enrollment: "Open enrollment",
    pending_interest: "Coming soon",
  }
  return types[type]
}

export const getProjectType = (type, variant = "long") => {
  const projectType = PROJECT_TYPES[type] || PROJECT_TYPES.other

  return projectType[variant] || projectType.long
}

export const getProjectBadgeType = (status) => {
  const badges = {
    [LANDOWNER_STATUS.eligible]: PROJECT_BADGE_TYPES.primary,
    [LANDOWNER_STATUS.not_interested]: PROJECT_BADGE_TYPES.info,
    [LANDOWNER_STATUS.request_information]: PROJECT_BADGE_TYPES.neutral,
    [LANDOWNER_STATUS.ineligible]: PROJECT_BADGE_TYPES.error,
    [LANDOWNER_STATUS.in_progress]: PROJECT_BADGE_TYPES.neutral,
    [LANDOWNER_STATUS.under_contract]: PROJECT_BADGE_TYPES.primary,
    [LANDOWNER_STATUS.information_needed]: PROJECT_BADGE_TYPES.warning,
    default: PROJECT_BADGE_TYPES.neutral,
  }

  return badges[status] || badges.default
}

export const getProjectBadgeText = (type) => {
  const interests = {
    [LANDOWNER_STATUS.eligible]: LANDOWNER_STATUS_MESSAGE.eligible,
    [LANDOWNER_STATUS.not_interested]: LANDOWNER_STATUS_MESSAGE.not_interested,
    [LANDOWNER_STATUS.request_information]:
      LANDOWNER_STATUS_MESSAGE.request_information,
    [LANDOWNER_STATUS.ineligible]: LANDOWNER_STATUS_MESSAGE.ineligible,
    [LANDOWNER_STATUS.determining_eligibility]:
      LANDOWNER_STATUS_MESSAGE.determining_eligibility,
    [LANDOWNER_STATUS.under_contract]: LANDOWNER_STATUS_MESSAGE.under_contract,
    [LANDOWNER_STATUS.information_needed]:
      LANDOWNER_STATUS_MESSAGE.information_needed,
    default: LANDOWNER_STATUS_MESSAGE.determining_eligibility,
  }
  return interests[type] || interests.default
}

export const getProjectEarningsNullValue = (type) => {
  const projectType = PROJECT_TYPES[type]
  return projectType?.earningsNullLabel || "Earnings vary"
}

const sortProjectsBy = {
  [PROJECT_SORTING.RECENTLY_ADDED]: (a, b) => {
    const dateA = new Date(a?.updated_at)
    const dateB = new Date(b?.updated_at)
    return dateB - dateA
  },
  [PROJECT_SORTING.MOST_POPULAR]: (a, b) => {
    return b.num_accounts_interested - a.num_accounts_interested
  },
  [PROJECT_SORTING.VERIFIED]: (a, b) => {
    return b.is_verified - a.is_verified
  },
  [PROJECT_SORTING.ELIGIBILITY]: (a, b) => {
    // DEV: assign each badge_display a numeric value in descending order
    const badgeVal = (badge) => {
      const badges = {
        [LANDOWNER_STATUS.under_contract]: 7,
        [LANDOWNER_STATUS.request_information]: 6,
        [LANDOWNER_STATUS.eligible]: 5,
        [LANDOWNER_STATUS.information_needed]: 4,
        [LANDOWNER_STATUS.determining_eligibility]: 3,
        [LANDOWNER_STATUS.not_interested]: 2,
        [LANDOWNER_STATUS.ineligible]: 1,
        default: 3,
      }
      return badges[badge] || badges.default
    }

    // DEV: then sort by badge display
    if (badgeVal(a.badge_display) > badgeVal(b.badge_display)) {
      return -1
    }
    if (badgeVal(a.badge_display) < badgeVal(b.badge_display)) {
      return 1
    }

    // DEV: finally, check if gov vs non-gov and place gov last
    if (a.is_government_program < b.is_government_program) return -1
    if (a.is_government_program > b.is_government_program) return 1
  },
  [PROJECT_SORTING.ENROLLMENT_DEADLINE]: (a, b) => {
    const dateA = new Date(a?.enrollment_deadline_date)
    const dateB = new Date(b?.enrollment_deadline_date)

    // DEV: assign each enrollment_deadline_type a numeric value in descending order
    const deadlineTypeVal = {
      [DEADLINE_TYPE.specific_date]: 3,
      [DEADLINE_TYPE.open_enrollment]: 2,
      [DEADLINE_TYPE.pending_interest]: 1,
    }

    // DEV: then sort by deadline type
    if (
      deadlineTypeVal[a.enrollment_deadline_type] >
      deadlineTypeVal[b.enrollment_deadline_type]
    ) {
      return -1
    }
    if (
      deadlineTypeVal[a.enrollment_deadline_type] <
      deadlineTypeVal[b.enrollment_deadline_type]
    ) {
      return 1
    }

    // DEV: then check if gov vs non-gov and place gov last
    if (a.is_government_program < b.is_government_program) return -1
    if (a.is_government_program > b.is_government_program) return 1

    // DEV: finally, sort by date
    if (
      a.enrollment_deadline_type === DEADLINE_TYPE.specific_date &&
      b.enrollment_deadline_type === DEADLINE_TYPE.specific_date
    )
      return dateA - dateB
  },
  [PROJECT_SORTING.POTENTIAL_EARNINGS]: (a, b) => {
    return b.potential_earnings - a.potential_earnings
  },
}

const extractProjectStates = (project) => {
  const requirements = project.overview_information?.eligibility_requirements
  let states = []
  Object.values(requirements).forEach((req) => {
    if (typeof req === "string") {
      // first look for most common: state abbreviations
      const stateList = req.match(stateAbbreviationPattern)
      states.push(stateList)
      if (!stateList) {
        // then look for full state name, when it's one.
        const stateName = req.match(stateNamePattern)
        if (stateName) {
          states.push(
            find(stateOptions, (stateOption) => {
              return stateOption[1].toLowerCase() === stateName[0].toLowerCase()
            })[0]
          )
        }
      }
      if (req.includes("48 states")) {
        states = conus
      }
    }
  })
  states = flatten(states).filter(Boolean)
  // if no states found, return all 50 states
  return states.length > 0 ? states : stateAbbreviations
}

export const getProjectsData = (data) => {
  const projectsData = data?.project_data?.map((project) => {
    const accountProject = data.account_project_data.find(
      (accProject) => project.id === accProject.project_id
    )
    const newProject = {
      ...project,
      image_url:
        project?.image_url?.replace(/^(.+?\.(png|svg)).*$/i, "$1") ||
        PLACEHOLDER,
      not_interested_reason: accountProject?.not_interested_reason,
      not_interested_reason_additional:
        accountProject?.not_interested_reason_additional,
      landowner_status: accountProject?.status,
      badge_display: accountProject?.badge_display,
      is_eligible: accountProject?.is_eligible ?? null,
      ineligibility_reasons: accountProject?.ineligibility_reasons ?? undefined,
      eligible_acres: accountProject?.eligible_acres ?? null,
      states: extractProjectStates(project),
    }
    return newProject
  })

  return projectsData ?? []
}

export const filterProjectsData = (
  projectsData,
  sortBy,
  category,
  govtProgramsOn,
  ineligibleProjectsOn,
  hasLandownerCost,
  termLengthFilter,
  paymentTypes,
  searchProjects
) => {
  let filteredData = projectsData?.sort(sortProjectsBy[sortBy])
  let checkedPaymentTypes = []

  if (paymentTypes) {
    checkedPaymentTypes = Object?.entries(paymentTypes)
      .filter((paymentTerm) => paymentTerm[1])
      .map((paymentTerm) => paymentTerm[0])
  }

  if (govtProgramsOn === false) {
    filteredData = filteredData.filter(
      (project) => project.is_government_program === false
    )
  }

  if (ineligibleProjectsOn === false) {
    filteredData = filteredData.filter(
      (project) => project.is_eligible !== false
    )
  }

  if (hasLandownerCost === false) {
    filteredData = filteredData.filter(
      (project) =>
        project.lo_cost === null ||
        project.lo_cost === undefined ||
        project.lo_cost === 0
    )
  }

  if (termLengthFilter && termLengthFilter[0] > 0) {
    filteredData = filteredData.filter(
      (project) => project.term === null || project.term >= termLengthFilter[0]
    )
  }

  if (termLengthFilter && termLengthFilter[1] < 100) {
    filteredData = filteredData.filter(
      (project) => project.term === null || project.term <= termLengthFilter[1]
    )
  }

  if (checkedPaymentTypes?.length > 0) {
    filteredData = filteredData.filter(({ payment_types }) =>
      payment_types?.some(({ type }) => checkedPaymentTypes.includes(type))
    )
  }

  if (category) {
    filteredData = filteredData.filter((project) => project.type === category)
  }

  if (searchProjects.length > 0) {
    filteredData = filteredData.filter(
      (project) =>
        project.name.toLowerCase().includes(searchProjects.toLowerCase()) ||
        project.developer
          .toLowerCase()
          .includes(searchProjects.toLowerCase()) ||
        project.description_short
          .toLowerCase()
          .includes(searchProjects.toLowerCase()) ||
        project.description_long
          .toLowerCase()
          .includes(searchProjects.toLowerCase()) ||
        // include states in search both as full name and abbreviation
        project.states
          .map((state) => [
            state.toLowerCase(),
            find(
              stateOptions,
              (stateOption) => stateOption[0] === state.toUpperCase()
            )[1].toLowerCase(),
          ])
          .flat()
          .join(",")
          .includes(searchProjects.toLowerCase())
    )
  }

  return filteredData
}

export function onWheel(apiObj, ev) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15

  if (isThouchpad) {
    ev.stopPropagation()
    return
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext()
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev()
  }
}

export const getProjectData = (data, projectsData, projectId) => {
  const accountProjectData = data?.account_project_data?.find(
    (project) => project.project_id === projectId
  )
  const projectData = projectsData?.find((project) => project.id === projectId)

  if (projectData === undefined) {
    return undefined
  }

  return {
    ...projectData,
    badge_display: accountProjectData?.badge_display,
    has_service_provider_coverage:
      accountProjectData?.has_service_provider_coverage,
  }
}

export const getStackableProjectsData = (stackableIds, projectsData) => {
  const stackableProjectsData = stackableIds?.map((projectId) =>
    projectsData?.find((project) => project.id === projectId)
  )
  // endpoint returns all ids but we only care about the top 5
  return stackableProjectsData?.filter(Boolean).slice(0, 5) ?? []
}

export const allAttestationsHaveValue = (attestationsData) =>
  Object.values(attestationsData).length > 0 &&
  Object.values(attestationsData).every((attestation) =>
    Object.prototype.hasOwnProperty.call(attestation, "value")
  )

export const getSVGURI = (faIcon, color) => {
  const abstract = icon(faIcon).abstract[0]
  if (color) abstract.children[0].attributes.fill = color
  return `data:image/svg+xml;base64,${window.btoa(toHtml(abstract))}`
}

export const secondsToMinutes = (totalSeconds) => {
  const minutes = Math.ceil(totalSeconds / 60)
  const unit = minutes > 1 ? "minutes" : "minute"

  return `${minutes} ${unit}`
}

export const getProjectTypeFilters = (projectTypes, category) => {
  const projectTypeFilters = projectTypes?.project_types.map((projectType) => ({
    id: projectType,
    tag: PROJECT_TYPES[projectType].long,
    selected: projectType === category ? true : false,
  }))

  // DEV: Sort projectTypeFilters based on the order of keys in PROJECT_TYPES
  projectTypeFilters?.sort(
    (a, b) =>
      Object.keys(PROJECT_TYPES).indexOf(a.id) -
      Object.keys(PROJECT_TYPES).indexOf(b.id)
  )

  return projectTypeFilters
}

export const startIdentityVerification = async (
  queryClient,
  idvRequestState
) => {
  try {
    if (idvRequestState === "pending") return
    queryClient.setQueryData(["idv-request-state"], () => "pending")
    const data = await getIdentityVerificationToken()
    const client = new Persona.Client({
      inquiryId: data.inquiry_id,
      sessionToken: data.session_token,
      onReady: () => {
        client.open()
        queryClient.setQueryData(["idv-request-state"], () => "incomplete")
      },
      onComplete: () =>
        queryClient.setQueryData(["idv-request-state"], () => "complete"),
      onError: (error) => {
        Toast.error(error)
        queryClient.setQueryData(["idv-request-state"], () => "incomplete")
      },
      onCancel: () =>
        queryClient.setQueryData(["idv-request-state"], () => "incomplete"),
    })
  } catch (e) {
    queryClient.setQueryData(["idv-request-state"], () => "incomplete")
    Toast.error(`Error communicating with identity server`)
  }
}

export const getArrowItemsObject = (items) => {
  const itemsObject = {}
  items.forEach((value, key) => {
    if (!key.includes("separator")) {
      itemsObject[key] = value
    }
  })
  return itemsObject
}

export function getTimePassedText(dateString) {
  const givenDate = DateTime.fromISO(dateString)
  const currentDate = DateTime.now()
  const isSameDate = givenDate.hasSame(currentDate, "day")
  const isYesterday = givenDate.hasSame(currentDate.minus({ days: 1 }), "day")

  if (isSameDate) {
    return "Today"
  } else if (isYesterday) {
    return "Yesterday"
  } else {
    const diffDays = Math.floor(
      currentDate.diff(givenDate, "days").toObject().days
    )
    const diffMonths = Math.floor(
      currentDate.diff(givenDate, "months").toObject().months
    )
    const diffYears = Math.floor(
      currentDate.diff(givenDate, "years").toObject().years
    )

    if (diffDays === 1) {
      return "1 day ago"
    } else if (diffDays > 1 && diffDays < 30) {
      return `${diffDays} days ago`
    } else if (diffMonths === 1) {
      return "1 month ago"
    } else if (diffMonths > 1 && diffMonths < 12) {
      return `${diffMonths} months ago`
    } else if (diffYears === 1) {
      return "1 year ago"
    } else {
      return `${diffYears} years ago`
    }
  }
}

export const transformBounds = (bounds) => {
  // return empty array if user has no bounds drawn
  if (!bounds?.coordinates) return []
  return bounds.coordinates.map((coordinateGroup, index) => {
    return {
      id: `feature-${index}`,
      type: "Feature",
      properties: {},
      geometry: {
        coordinates: coordinateGroup,
        type: "Polygon",
      },
    }
  })
}

export const DEFAULT_ZOOM_LEVEL = 12

export const getViewportFromFeatures = (features) => {
  if (features?.length === 0) return null

  const bounds = bbox({
    type: "FeatureCollection",
    features: features,
  })

  const [minLng, minLat, maxLng, maxLat] = bounds

  const longitude = (minLng + maxLng) / 2
  const latitude = (minLat + maxLat) / 2

  // DEV: Calculate the zoom level based on the bounding box
  const deltaLng = maxLng - minLng
  const deltaLat = maxLat - minLat
  const zoom = Math.min(
    Math.floor(
      Math.min(
        Math.log2((360 * (window.innerWidth / 512)) / deltaLng),
        Math.log2((170 * (window.innerHeight / 512)) / deltaLat)
      )
    ),
    20
  )

  return {
    longitude,
    latitude,
    zoom: zoom || DEFAULT_ZOOM_LEVEL,
  }
}

export function areArraysEqual(firstArray, secondArray) {
  return firstArray.every(
    (item, index) => item.toString() === secondArray[index].toString()
  )
}

export function isDMS(value) {
  const addressSplittedByComma = value.split(",").map((item) => item.trim())
  const addressAsDD = addressSplittedByComma.map(parseDms)

  // if any item is NaN after parsing, `value` is neither DMS nor DD
  if (addressAsDD.some((item) => Number.isNaN(item))) {
    return false
  }

  // value is DMS if parsed array is not equal to `value`
  // (i.e. value array after splitted by comma, since `value` is string)
  return !areArraysEqual(addressSplittedByComma, addressAsDD)
}
